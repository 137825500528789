import React, { useEffect } from 'react';
import _ from "lodash";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PieChartIcon from '@material-ui/icons/PieChart';
import ListIcon from '@material-ui/icons/List';
import TabPanel from '../../Tabs/TabPanel';
import SimplePieChart from '../../Charts/SimplePieChart';
import IncomesByCategoryList from './IncomesByCategoryList';
import { getIncomesByCategory } from '../../../services/analyticsService';
import { convertDateToApiParamString } from '../../../utils/dateUtils';
import { IncomesByCategory } from '../Analytics';
import ChartHeader from '../../Charts/ChartHeader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface IncomesByCategoryTabProps {
    startDate: Date
    endDate: Date
}

const fetchIncomesByCategory = async (startDate: Date, endDate: Date) => {
    const response = await getIncomesByCategory({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}

const IncomesByCategoryTab = (props: IncomesByCategoryTabProps) => {
    const classes = useStyles();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [pieChartData, setPieChartData] = React.useState([]);
    const [incomes, setIncomes] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        setLoaded(false)
        
        fetchIncomesByCategory(props.startDate, props.endDate)
            .then(data => {
                const grandTotal = _.sumBy(data, (e: IncomesByCategory) => e.total)
                const pieChartData = data.map((expense: IncomesByCategory) => {
                    return {
                        name: expense.categoryName,
                        value: expense.total
                    }    
                })
                const incomes = data.map((expense: IncomesByCategory) => {
                    return {
                        category: expense.categoryName,
                        amount: expense.total,
                        share: ((expense.total / grandTotal) * 100).toFixed(2) + " %"
                    }
                })
                const orderedExpenses: any = _.orderBy(incomes, ['amount'], ['desc'])
                
                setPieChartData(pieChartData)
                setIncomes(orderedExpenses)
                setLoaded(true)
            })
            .catch(e => setLoaded(true))
    }, [props.startDate, props.endDate])

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="incomes-by-category-tabs"
                >
                    <Tab label="Chart" icon={<PieChartIcon />} />
                    <Tab label="List" icon={<ListIcon />} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTabIndex} index={0} id={'incomes-by-category-chart'} ariaLabel={'Incomes by category chart'}>
                <ChartHeader
                    title='Category-wise Incomes'
                    csvData={incomes}
                />
                <SimplePieChart loaded={loaded} data={pieChartData}/>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} id={'incomes-by-category-list'} ariaLabel={'Incomes by category list'}>
                <ChartHeader
                    title='Category-wise Incomes'
                    csvData={incomes}
                />
                <IncomesByCategoryList incomesByCategory={incomes}/>
            </TabPanel>
        </div>
    );
}

export default IncomesByCategoryTab
