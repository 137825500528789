import React from "react";
import {AllTimeStats as AllTimeStatsState} from "../Analytics";
import { getAllTimeStats } from "../../../services/analyticsService";
import { Grid, Card, CardContent, Box, CircularProgress } from "@material-ui/core";
import StatsItem from "./StatsItem";
import Amount from "../../App/Currency/Amount";

class AllTimeStats extends React.Component<{}, AllTimeStatsState> {
    constructor(props: any) {
        super(props)
        this.state = {
            allTimeAvgExpense: 0,
            allTimeHighExpense: null,
            allTimeLowExpense: null,
            numberOfExpenses: 0,
            totalDeductions: 0,
            totalExpenses: 0,
            totalIncome: 0,
            totalSavings: 0,
            loaded: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await getAllTimeStats()
            const data = response.data.data;

            this.setState({
                allTimeAvgExpense: data.allTimeAvgExpense ? data.allTimeAvgExpense : 0,
                allTimeHighExpense: data.allTimeHighExpense,
                allTimeLowExpense: data.allTimeLowExpense,
                numberOfExpenses: data.numberOfExpenses ? data.numberOfExpenses : 0,
                totalDeductions: data.totalDeductions ? data.totalDeductions : 0,
                totalExpenses: data.totalExpenses ? data.totalExpenses : 0,
                totalIncome: data.totalIncome ? data.totalIncome : 0,
                totalSavings: data.totalSavings ? data.totalSavings : 0,
                loaded: true
            })
        } catch (e) {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        if (!this.state.loaded) {
            return(
                <Box textAlign="center"><CircularProgress/></Box>
            )
        }

        return(
            <Grid container spacing={1}>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.allTimeHighExpense? this.state.allTimeHighExpense.amount: 0}/>}
                        subHeader={'Highest Expense'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.allTimeLowExpense ? this.state.allTimeLowExpense.amount : 0}/>}
                        subHeader={'Lowest Expense'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.allTimeAvgExpense}/>}
                        subHeader={'Average Expense'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={this.state.numberOfExpenses}
                        subHeader={'No. of Expenses'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.totalExpenses}/>}
                        subHeader={'Total Expenditure'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.totalSavings}/>}
                        subHeader={'Total Savings'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.totalDeductions}/>}
                        subHeader={'Total Deductions'}
                        icon={null}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <StatsItem
                        header={<Amount value={this.state.totalIncome}/>}
                        subHeader={'Total Income'}
                        icon={null}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default AllTimeStats