import React from "react";
import _ from "lodash";
import { Formik, Form, Field } from 'formik';
import { Select } from 'formik-material-ui';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Preferences } from "./Preferences";
import { Category } from "../../Categories/Category";
import { Account } from "../../Accounts/Account";
import { currencies, Currency } from "../../../components/App/Currency/Currency";

type PaymentType = {
    title: string
    code: string
}

const paymentTypes: PaymentType[] = [
    { title: "Cash", code: "CASH" },
    { title: "Online Banking", code: "ONLINE_BANKING" },
    { title: "Debit Card", code: "DEBIT_CARD" },
    { title: "Credit Card", code: "CREDIT_CARD" },
    { title: "PayTM", code: "PAYTM" },
    { title: "UPI", code: "UPI" }
]

interface PreferencesFormProps {
    preferences: Preferences,
    expensesCategories: Category[],
    incomeSources: Category[],
    savingsCategories: Category[],
    deductionsCategories: Category[],
    accounts: Account[],
    submitForm: (preferences: Preferences) => void
}

const PreferencesForm = (props: PreferencesFormProps) => {
    const { preferences } = props
    const initialValues: Preferences = {
        currency: preferences.currency || "inr",
        defaultDeductionCategory: preferences.defaultDeductionCategory || "-1",
        defaultDeductionsAccount: preferences.defaultDeductionsAccount || "-1",
        defaultExpenseAccount: preferences.defaultExpenseAccount || "-1",
        defaultExpenseCategory: preferences.defaultExpenseCategory || "-1",
        defaultExpenseSubCategory: preferences.defaultExpenseSubCategory || "-1",
        defaultIncomeAccount: preferences.defaultIncomeAccount || "-1",
        defaultIncomeCategory: preferences.defaultIncomeCategory || "-1",
        defaultPaymentType: preferences.defaultPaymentType || "-1",
        defaultSavingsAccount: preferences.defaultSavingsAccount || "-1",
        defaultSavingsCategory: preferences.defaultSavingsCategory || "-1",
        theme: preferences.theme || "dark"
    }

    const renderCurrency = () => {
        const currencyList: JSX.Element[] = [];
        currencies.forEach((c: Currency) => (
            currencyList.push(<MenuItem key={c.code} value={c.code}>{c.name}</MenuItem>)
        ))

        return (
            <FormControl fullWidth>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Field
                    component={Select}
                    name="currency"
                    inputProps={{
                        id: 'currency'
                    }}
                >
                    {currencyList}
                </Field>
            </FormControl>
        )
    }
    const renderExpenseSubCategories = (categoryId: string) => {
        let subCategories: Category[] = [];
        if (categoryId) {
            const category = _.find(props.expensesCategories, (e) => e.id === categoryId)
            if (category && category.subCategories) {
                subCategories = category.subCategories;
            }
        }

        return (
            <FormControl fullWidth>
                <InputLabel variant={'filled'} htmlFor="sub-category">Default Expense Sub Category</InputLabel>
                <Field
                    disabled={!categoryId}
                    className={'Form-input'}
                    component={Select}
                    name="defaultExpenseSubCategory"
                    inputProps={{
                        id: "sub-category"
                    }}
                >
                    <MenuItem key={'-1'} value={'-1'}>None</MenuItem>
                    {
                        subCategories.map((c: Category) => (
                            <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                        ))
                    }
                </Field>
            </FormControl>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(formValues, { setSubmitting }) => {
                const values = { ...formValues }

                props.submitForm(values)
                setSubmitting(false)
            }}
        >
            {({ values, dirty, resetForm, submitForm }) => (
                <Form>
                    {renderCurrency()}
                    <FormControl fullWidth>
                        <InputLabel htmlFor="theme">Default Theme</InputLabel>
                        <Field
                            component={Select}
                            name="theme"
                            inputProps={{
                                id: 'theme'
                            }}
                        >
                            <MenuItem key="dark" value="dark">Dark</MenuItem>
                            <MenuItem key="light" value="light">Light</MenuItem>
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-expense-category">Default Expense Category</InputLabel>
                        <Field
                            component={Select}
                            name="defaultExpenseCategory"
                            inputProps={{
                                id: 'default-expense-category'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.expensesCategories.map((c: Category) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    {renderExpenseSubCategories(values.defaultExpenseCategory)}
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-expense-account">Default Expense Account</InputLabel>
                        <Field
                            component={Select}
                            name="defaultExpenseAccount"
                            inputProps={{
                                id: 'default-expense-account'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.accounts.map((c: Account) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-savings-category">Default Savings Category</InputLabel>
                        <Field
                            component={Select}
                            name="defaultSavingsCategory"
                            inputProps={{
                                id: 'default-savings-category'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.savingsCategories.map((c: Category) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-savings-account">Default Savings Account</InputLabel>
                        <Field
                            component={Select}
                            name="defaultSavingsAccount"
                            inputProps={{
                                id: 'default-savings-account'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.accounts.map((c: Account) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-deduction-category">Default Deduction Category</InputLabel>
                        <Field
                            component={Select}
                            name="defaultDeductionCategory"
                            inputProps={{
                                id: 'default-deduction-category'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.deductionsCategories.map((c: Category) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-deductions-account">Default Deduction Account</InputLabel>
                        <Field
                            component={Select}
                            name="defaultDeductionsAccount"
                            inputProps={{
                                id: 'default-deductions-account'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.accounts.map((c: Account) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-income-source">Default Income Source</InputLabel>
                        <Field
                            component={Select}
                            name="defaultIncomeCategory"
                            inputProps={{
                                id: 'default-income-source'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.incomeSources.map((c: Category) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-income-account">Default Income Account</InputLabel>
                        <Field
                            component={Select}
                            name="defaultIncomeAccount"
                            inputProps={{
                                id: 'default-income-account'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                props.accounts.map((c: Account) => (
                                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="default-payment-type">Default Payment Type</InputLabel>
                        <Field
                            component={Select}
                            name="defaultPaymentType"
                            inputProps={{
                                id: 'default-payment-type'
                            }}
                        >
                            <MenuItem key="-1" value="-1">None</MenuItem>
                            {
                                paymentTypes.map((c: PaymentType) => (
                                    <MenuItem key={c.code} value={c.code}>{c.title}</MenuItem>
                                ))
                            }
                        </Field>
                    </FormControl>
                    <div className="Form-actions">
                        <Button disabled={!dirty} onClick={() => resetForm()} className="Form-action-btn" variant="contained" color="default">
                            Reset
                        </Button>
                        <Button disabled={!dirty} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default PreferencesForm