import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Tooltip, IconButton, Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { RootState } from "../../store";
import { fetchAllAccounts, addAccount, editAccount, removeAccount } from "../../store/accounts/actions";
import AccountList from "./AccountList";
import { Account, AddOrEditAccountRequestBody } from "./Account";
import AddOrEditAccountForm from "./AddOrEditAccountForm";
import DeleteResourceForm from "../Forms/DeleteResourceForm";
import FormDialog from "../Dialog/FormDialog";

const mapStateToProps = (state: RootState) => ({
    accounts: state.accounts
})
const mapDispatchToProps = {
    fetchAllAccounts, addAccount,
    editAccount, removeAccount
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type AccountsProps = ConnectedProps<typeof connector>
type FormType = 'add_account' | 'edit_account' | 'delete_account'
interface FormStatus {
    formType: FormType | null
    formTitle: string
    selectedAccount: Account | null
}
interface AccountsState {
    formStatus: FormStatus
}

class Accounts extends Component<AccountsProps, AccountsState> {
    constructor(props: AccountsProps) {
        super(props)
        this.state = {
            formStatus: {
                formTitle: "",
                formType: null,
                selectedAccount: null
            }
        }
    }

    componentDidMount() {
        this.props.fetchAllAccounts()
    }

    setFormStatus = (formStatus: FormStatus) => {
        this.setState({
            formStatus: formStatus
        })
    }

    closeForm = () => {
        this.setFormStatus({
            formType: null,
            formTitle: "",
            selectedAccount: null
        })
    }

    openAddAccountForm = () => {
        this.setFormStatus({
            formType: "add_account",
            formTitle: "Add New Account",
            selectedAccount: null
        })
    }

    openEditAccountForm = (account: Account) => {
        this.setFormStatus({
            formType: "edit_account",
            formTitle: "Edit Account",
            selectedAccount: account
        })
    }

    openDeleteAccountForm = (account: Account) => {
        this.setFormStatus({
            formType: "delete_account",
            formTitle: "Delete Account",
            selectedAccount: account
        })
    }

    submitAddAccountForm = (formValues: AddOrEditAccountRequestBody) => {
        this.closeForm()
        this.props.addAccount(formValues)
    }

    submitEditAccountForm = (formValues: AddOrEditAccountRequestBody) => {
        if (this.state.formStatus.selectedAccount) {
            const id = this.state.formStatus.selectedAccount.id
            this.props.editAccount(id, formValues)
            this.closeForm()
        }
    }

    submitDeleteAccountForm = () => {
        if (this.state.formStatus.selectedAccount) {
            const id = this.state.formStatus.selectedAccount.id
            this.props.removeAccount(id)
            this.closeForm()
        }
    }

    renderFormDialog = () => {
        if (!this.state.formStatus.formType) {
            return null
        }

        let formComponent = null
        switch(this.state.formStatus.formType) {
            case 'add_account': {
                formComponent = <AddOrEditAccountForm
                    submitForm={this.submitAddAccountForm}
                    closeForm={this.closeForm}
                />
                break
            }
            case 'edit_account': {
                formComponent = <AddOrEditAccountForm
                    account={this.state.formStatus.selectedAccount}
                    submitForm={this.submitEditAccountForm}
                    closeForm={this.closeForm}
                />
                break
            }
            case 'delete_account': {
                formComponent = <DeleteResourceForm
                    resource={this.state.formStatus.selectedAccount}
                    submitForm={this.submitDeleteAccountForm}
                    cancel={this.closeForm}
                />
                break
            }
            default: return null
        }

        return (
            <FormDialog
                title={this.state.formStatus.formTitle}
                closeDialog={this.closeForm}
                open
            >
                {formComponent}
            </FormDialog>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <AccountList
                            accounts={this.props.accounts}
                            addAccount={this.openAddAccountForm}
                            editAccount={this.openEditAccountForm}
                            deleteAccount={this.openDeleteAccountForm}
                        />
                    </Grid>
                </Grid>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default connector(Accounts)
