import { batch } from "react-redux";
import { AppThunk } from "..";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import { FETCH_ALL_EXPENSES, REMOVE_EXPENSE, ADD_EXPENSE, EDIT_EXPENSE } from "./types";
import { AddOrEditExpenseRequestBody } from "../../components/Expenses/Expense";
import { createExpense, updateExpense, deleteExpense, getAllExpenses } from "../../services/expensesService";

export const fetchAllExpenses = (params?: any): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllExpenses(params)

        batch(() => {
            dispatch({
                type: FETCH_ALL_EXPENSES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addExpense = (requestBody: AddOrEditExpenseRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createExpense(requestBody)

        batch(() => {
            dispatch({
                type: ADD_EXPENSE,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Expense added successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editExpense = (id: string, requestBody: AddOrEditExpenseRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateExpense(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_EXPENSE,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Expense updated successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeExpense = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteExpense(id)

        batch(() => {
            dispatch({
                type: REMOVE_EXPENSE,
                payload: { id }
            })

            dispatch(hidePageLoader())
            dispatch(showNotification("Expense removed successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}