import React, { Component } from 'react'
import _ from "lodash";
import { RootState } from "../../store";
import {
    fetchAllExpenses,
    addExpense,
    editExpense,
    removeExpense
} from "../../store/expenses/actions";
import {
    fetchAllExpensesCategories,
    addExpensesCategory,
    editExpensesCategory,
    removeExpensesCategory
} from "../../store/categories/actions";
import { fetchAllAccounts } from "../../store/accounts/actions";
import { connect, ConnectedProps } from "react-redux";
import { Expense, AddOrEditExpenseRequestBody } from './Expense';
import FormDialog from '../Dialog/FormDialog';
import AddOrEditExpenseForm from './AddOrEditExpenseForm';
import DeleteExpenseForm from './DeleteExpenseForm';
import ExpensesTable from './ExpensesTable';
import { Grid } from '@material-ui/core';
import { Category } from '../Categories/Category';
import { AddOrEditCategoryRequestBody } from '../../store/categories/types';
import AddOrEditCategoryForm from '../Categories/AddOrEditCategoryForm';
import DeleteCategoryForm from '../Categories/DeleteCategoryForm';
import CategoryList from '../Categories/CategoryList';
import moment from 'moment';
import DurationPicker from '../Duration/DurationPicker';
import { currentMonthStartDate, currentMonthEndDate } from '../Duration/DurationConfig';
import { convertDateToApiParamString, isWithInRange } from '../../utils/dateUtils';
import { isWithinInterval } from 'date-fns';

const mapStateToProps = (state: RootState) => ({
    expenses: state.expenses,
    accounts: state.accounts,
    expensesCategories: state.categories.expenses,
    defaultExpenseCategpryId: state.settings.preferences.defaultExpenseCategory,
    defaultExpenseSubCategoryId: state.settings.preferences.defaultExpenseSubCategory,
    defaultPaymentType: state.settings.preferences.defaultPaymentType,
    defaultAccountId: state.settings.preferences.defaultExpenseAccount
})
const mapDispatchToProps = {
    fetchAllExpenses, addExpense,
    editExpense, removeExpense,
    fetchAllExpensesCategories, addExpensesCategory,
    editExpensesCategory, removeExpensesCategory,
    fetchAllAccounts
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type ExpensesProps = ConnectedProps<typeof connector>
type ExpensesFormType = 'add_expense' | 'edit_expense' | 'delete_expense'
type ExpensesCategoryFormType = 'add_expenses_category' | 'edit_expenses_category' | 'delete_expenses_category'
type FormType = ExpensesFormType | ExpensesCategoryFormType
type SelectedExpense = Expense | null
type SelectedExpenseCategory = Category | null
type FormStatus = {
    formTitle: string
    formType: FormType | null
    selectedExpense: SelectedExpense
    selectedExpenseCategory: SelectedExpenseCategory
}
type ExpensesState = {
    formStatus: FormStatus
}

class Expenses extends Component<ExpensesProps, ExpensesState> {
    startDate: Date = currentMonthStartDate;
    endDate: Date = currentMonthEndDate;

    constructor(props: ExpensesProps) {
        super(props)
        this.state = {
            formStatus: {
                formTitle: "",
                formType: null,
                selectedExpense: null,
                selectedExpenseCategory: null
            }
        }
    }

    componentDidMount(): void {
        this.props.fetchAllExpenses({
            startDate: convertDateToApiParamString(currentMonthStartDate),
            endDate: convertDateToApiParamString(currentMonthEndDate)
        })
        this.props.fetchAllExpensesCategories()
        this.props.fetchAllAccounts()
    }

    getDefaultExpense = (): Expense => {
        return {
            id: '', name: '', date: new Date().toISOString(), amount: 0, categoryId: this.props.defaultExpenseCategpryId,
            categoryName: '', subCategoryId: this.props.defaultExpenseSubCategoryId, subCategoryName: '',
            paymentType: this.props.defaultPaymentType, accountId: this.props.defaultAccountId
        }
    }

    setExpensesFormStatus = (formTitle: string, formType: ExpensesFormType | null, selectedExpense: SelectedExpense = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedExpense, selectedExpenseCategory: null
            }
        })
    }

    setExpensesCategoryFormStatus = (formTitle: string, formType: ExpensesCategoryFormType | null, selectedExpenseCategory: SelectedExpenseCategory = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedExpenseCategory, selectedExpense: null
            }
        })
    }

    closeForm = () => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle: "",
                formType: null,
                selectedExpense: null,
                selectedExpenseCategory: null
            }
        })
    }

    submitAddExpenseForm = (formValues: AddOrEditExpenseRequestBody) => {
        this.closeForm()
        this.props.addExpense(formValues)
    }

    submitEditExpenseForm = (formValues: AddOrEditExpenseRequestBody) => {
        if (this.state.formStatus.selectedExpense) {
            const id = this.state.formStatus.selectedExpense.id
            this.closeForm()
            this.props.editExpense(id, formValues)
        }
    }

    submitDeleteExpenseForm = () => {
        if (this.state.formStatus.selectedExpense) {
            const id = this.state.formStatus.selectedExpense.id
            this.closeForm()
            this.props.removeExpense(id)
        }
    }

    submitAddCategoryForm = (formValues: AddOrEditCategoryRequestBody) => {
        this.closeForm()
        this.props.addExpensesCategory(formValues)
    }

    submitEditCategoryForm = (formValues: AddOrEditCategoryRequestBody) => {
        if (this.state.formStatus.selectedExpenseCategory) {
            const id = this.state.formStatus.selectedExpenseCategory.id
            this.closeForm()
            this.props.editExpensesCategory(id, formValues)
        }
    }

    submitDeleteCategoryForm = () => {
        if (this.state.formStatus.selectedExpenseCategory) {
            const id = this.state.formStatus.selectedExpenseCategory.id
            this.closeForm()
            this.props.removeExpensesCategory(id)
        }
    }

    renderFormDialog = () => {
        if (this.state.formStatus.formType) {
            let formComponent = null

            switch (this.state.formStatus.formType) {
                case 'add_expense': {
                    formComponent = <AddOrEditExpenseForm
                        accounts={this.props.accounts}
                        expense={this.getDefaultExpense()}
                        submitForm={this.submitAddExpenseForm}
                        closeForm={this.closeForm}
                        expensesCategories={this.props.expensesCategories}
                    />
                    break
                }
                case 'edit_expense': {
                    formComponent = <AddOrEditExpenseForm
                        accounts={this.props.accounts}
                        submitForm={this.submitEditExpenseForm}
                        closeForm={this.closeForm}
                        expensesCategories={this.props.expensesCategories}
                        expense={this.state.formStatus.selectedExpense}
                    />
                    break
                }
                case 'delete_expense': {
                    if (this.state.formStatus.selectedExpense) {
                        formComponent = <DeleteExpenseForm
                            submitForm={this.submitDeleteExpenseForm}
                            closeForm={this.closeForm}
                            expense={this.state.formStatus.selectedExpense}
                        />
                    }
                    break
                }
                case 'add_expenses_category': {
                    formComponent = <AddOrEditCategoryForm
                        submitForm={this.submitAddCategoryForm}
                        closeForm={this.closeForm}
                        parentCategories={this.props.expensesCategories}
                    />
                    break
                }
                case 'edit_expenses_category': {
                    formComponent = <AddOrEditCategoryForm
                        submitForm={this.submitEditCategoryForm}
                        closeForm={this.closeForm}
                        category={this.state.formStatus.selectedExpenseCategory}
                    />
                    break
                }
                case 'delete_expenses_category': {
                    if (this.state.formStatus.selectedExpenseCategory) {
                        formComponent = <DeleteCategoryForm
                            submitForm={this.submitDeleteCategoryForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedExpenseCategory}
                        />
                    }
                    break
                }
            }

            return (
                <FormDialog
                    title={this.state.formStatus.formTitle}
                    closeDialog={this.closeForm}
                    open
                >
                    {formComponent}
                </FormDialog>
            )
        }

        return null
    }

    onDurationSelect = (range: Date[]) => {
        this.startDate = range[0];
        this.endDate = range[1];

        this.props.fetchAllExpenses({
            startDate: convertDateToApiParamString(range[0]),
            endDate: convertDateToApiParamString(range[1])
        })
    }

    openAddExpenseForm = () => this.setExpensesFormStatus("Add Expense", "add_expense")
    openEditExpenseForm = (expense: Expense) => this.setExpensesFormStatus("Edit Expense", "edit_expense", expense)
    openDeleteExpenseForm = (expense: Expense) => this.setExpensesFormStatus("Delete Expense", "delete_expense", expense)
    
    openAddCategoryForm = () => this.setExpensesCategoryFormStatus('Add Expense Category', 'add_expenses_category')
    openEditCategoryForm = (c: Category) => this.setExpensesCategoryFormStatus('Edit Expense Category', 'edit_expenses_category', c)
    openDeleteCategoryForm = (c: Category) => this.setExpensesCategoryFormStatus('Delete Expense Category', 'delete_expenses_category', c)

    render() {
        const expenses = _.filter(this.props.expenses, e => isWithInRange(new Date(e.date), this.startDate, this.endDate))

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DurationPicker onSelect={this.onDurationSelect} />
                    </Grid>
                    <Grid item xs={12}>
                        <ExpensesTable
                            expenses={expenses}
                            expensesCategories={this.props.expensesCategories}
                            accounts={this.props.accounts}
                            addExpense={this.openAddExpenseForm}
                            editExpense={this.openEditExpenseForm}
                            deleteExpense={this.openDeleteExpenseForm}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CategoryList
                            title={'Expense Categories'}
                            categories={this.props.expensesCategories}
                            addCategory={this.openAddCategoryForm}
                            editCategory={this.openEditCategoryForm}
                            deleteCategory={this.openDeleteCategoryForm}
                        />
                    </Grid>
                </Grid>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default connector(Expenses)