import React from 'react';
import { Formik, Form, Field, FormikErrors, FormikValues, ErrorMessage } from 'formik';
import { Deduction, AddOrEditDeductionRequestBody } from "./Deduction";
import { TextField, Select } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Category } from '../Categories/Category';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Account } from '../Accounts/Account';

interface AddOrEditDeductionFormValues {
    name: string
    amount: number | ''
    deductionCategoryId: string
    date: string
    accountId: string | null
}

type AddOrEditDeductionFormProps = {
    deduction?: Deduction | null
    accounts: Account[]
    deductionsCategories: Category[]
    submitForm: (formValues: AddOrEditDeductionRequestBody) => void
    closeForm: () => void
}

const AddOrEditDeductionForm = (props: AddOrEditDeductionFormProps) => {
    const initialValues: AddOrEditDeductionFormValues = {
        name: props.deduction ? props.deduction.name : '',
        amount: (props.deduction && props.deduction.amount) ? props.deduction.amount : '',
        deductionCategoryId: props.deduction && props.deduction.deductionCategoryId ? props.deduction.deductionCategoryId : '-1',
        date: props.deduction ? new Date(props.deduction.date).toISOString() : new Date().toISOString(),
        accountId: props.deduction && props.deduction.accountId ? props.deduction.accountId : '-1'
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: FormikErrors<FormikValues> = {}
                    if (!values.name || (!values.name.trim())) {
                        errors.name = "Name is required"
                    }
                    if (values.deductionCategoryId === "-1") {
                        errors.deductionCategoryId = "Category is required"
                    }
                    if (!values.amount || (values.amount <= 0)) {
                        errors.amount = "Amount should be > 0"
                    }
                    if (!values.date) {
                        errors.date = "Date is required"
                    }

                    return errors
                }}
                onSubmit={(values) => {
                    const formValues = {...values}
                    if (formValues.accountId === "-1") {
                        formValues.accountId = null
                    }

                    props.submitForm(formValues)
                }}
            >
                {({ isValid, touched, errors, dirty, submitForm }) => (
                    <Form>
                        <FormControl fullWidth>
                            <Field
                                autoFocus
                                className={'Form-input'}
                                component={TextField}
                                name="name"
                                label="Name"
                                required
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field
                                className={'Form-input'}
                                component={TextField}
                                name="amount"
                                label="Amount"
                                required
                                type="number"
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field inputProps={{autocomplete: "off"}} required format="dd MMM yyyy" component={DatePicker} label="Date" name="date" />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel error={Boolean(errors.deductionCategoryId && touched.deductionCategoryId)}required variant={'filled'} htmlFor="category">Category</InputLabel>
                            <Field
                                error={errors.deductionCategoryId && touched.deductionCategoryId}
                                className={'Form-input'}
                                component={Select}
                                name="deductionCategoryId"
                                inputProps={{
                                    id: "category",
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.deductionsCategories.map((c: Category) => (
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="deductionCategoryId" render={(msg: string) => <p className="MuiFormHelperText-root Mui-error Mui-required">{msg}</p>}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel variant={'filled'} htmlFor="account-id">Account</InputLabel>
                            <Field
                                className={'Form-input'}
                                component={Select}
                                name="accountId"
                                inputProps={{
                                    id: "account-id",
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.accounts.map((c: Account) => (
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                    ))
                                }
                            </Field>
                        </FormControl>
                        <div className="Form-actions">
                            <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="secondary">
                                Cancel
                            </Button>
                            <Button disabled={!dirty || !isValid} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
}

export default AddOrEditDeductionForm