import React, { useContext } from "react";
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
import { defaultWidth, defaultHeight, defaultMargin } from "./ChartsConfig";
import ThemeContext from "../../context/ThemeContext";
import CustomTooltip from "./CustomTooltip";
import { Box, CircularProgress } from "@material-ui/core";

interface BarConfig {
    key: string
    fillColor: string
}

interface SimpleBarChartProps {
    data: any
    xAxisDataKey: string
    barConfig: BarConfig[],
    showLegend?: boolean,
    loaded?: boolean
}

const SimpleBarChart = (props: SimpleBarChartProps) => {
    const { theme } = useContext(ThemeContext);
    const strokeColor = theme === "dark" ? "#fff" : "#000";

    const renderXAxis = () => <XAxis dataKey={props.xAxisDataKey} stroke={strokeColor}/>
    const renderYAxis = () => <YAxis stroke={strokeColor} tickFormatter={(v) => v.toLocaleString()}/>
    const renderTooltip = () => <Tooltip content={<CustomTooltip/>}/>
    const renderLegend = () => props.showLegend ? <Legend/> : null
    const renderBars = () => (
        props.barConfig.map((config: BarConfig) => (
            <Bar
                label={{
                    position: 'top',
                    stroke: strokeColor,
                    formatter: (label: string | number) => label.toLocaleString()
                }}
                key={config.key} dataKey={config.key} fill={config.fillColor}
            />
        ))
    )

    if (!props.loaded) {
        return(
            <Box textAlign="center"><CircularProgress/></Box>
        )
    }

    if (!props.data.length) {
        return <h3 style={{textAlign: "center"}}>No Data</h3>
    }

    return (
        <ResponsiveContainer width={defaultWidth} height={defaultHeight}>
            <BarChart margin={defaultMargin} data={props.data}>
                {renderXAxis()}
                {renderYAxis()}
                {renderTooltip()}
                {renderLegend()}
                {renderBars()}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default SimpleBarChart