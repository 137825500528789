import React, { useEffect } from 'react';
import _ from "lodash";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PieChartIcon from '@material-ui/icons/PieChart';
import ListIcon from '@material-ui/icons/List';
import TabPanel from '../../Tabs/TabPanel';
import SimplePieChart from '../../Charts/SimplePieChart';
import DeductionsByCategoryList from './DeductionsByCategoryList';
import { getDeductionsByCategory } from '../../../services/analyticsService';
import { convertDateToApiParamString } from '../../../utils/dateUtils';
import { DeductionsByCategory } from '../Analytics';
import ChartHeader from '../../Charts/ChartHeader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface DeductionsByCategoryTabProps {
    startDate: Date
    endDate: Date
}

const fetchDeductionsByCategory = async (startDate: Date, endDate: Date) => {
    const response = await getDeductionsByCategory({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}

const DeductionsByCategoryTab = (props: DeductionsByCategoryTabProps) => {
    const classes = useStyles();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [pieChartData, setPieChartData] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const [deductions, setDeductions] = React.useState([]);

    useEffect(() => {
        setLoaded(false)
        
        fetchDeductionsByCategory(props.startDate, props.endDate)
            .then(data => {
                const grandTotal = _.sumBy(data, (e: DeductionsByCategory) => e.total)
                const pieChartData = data.map((deductions: DeductionsByCategory) => {
                    return {
                        name: deductions.categoryName,
                        value: deductions.total
                    }    
                })
                const deductions = data.map((deduction: DeductionsByCategory) => {
                    return {
                        category: deduction.categoryName,
                        amount: deduction.total,
                        share: ((deduction.total / grandTotal) * 100).toFixed(2) + " %"
                    }
                })
                const orderedDeductions: any = _.orderBy(deductions, ['amount'], ['desc'])
                
                setPieChartData(pieChartData)
                setDeductions(orderedDeductions)
                setLoaded(true)
            })
            .catch(e => console.log(e))
    }, [props.startDate, props.endDate])

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="deductions-by-category-tabs"
                >
                    <Tab label="Chart" icon={<PieChartIcon />} />
                    <Tab label="List" icon={<ListIcon />} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTabIndex} index={0} id={'deductions-by-category-chart'} ariaLabel={'Deductions by category chart'}>
                <ChartHeader
                    title='Category-wise Deductions'
                    csvData={deductions}
                />
                <SimplePieChart loaded={loaded} data={pieChartData}/>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} id={'deductions-by-category-list'} ariaLabel={'Deductions by category list'}>
                <ChartHeader
                    title='Category-wise Deductions'
                    csvData={deductions}
                />
                <DeductionsByCategoryList deductionsByCategory={deductions}/>
            </TabPanel>
        </div>
    );
}

export default DeductionsByCategoryTab
