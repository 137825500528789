import React, { Component } from 'react'
import _ from "lodash";
import { RootState } from "../../store";
import {
    fetchAllDeductions,
    addDeduction,
    editDeduction,
    removeDeduction
} from "../../store/deductions/actions";
import {
    fetchAllDeductionsCategories,
    addDeductionsCategory,
    editDeductionsCategory,
    removeDeductionsCategory
} from "../../store/categories/actions";
import { fetchAllAccounts } from "../../store/accounts/actions";
import { connect, ConnectedProps } from "react-redux";
import { Deduction, AddOrEditDeductionRequestBody } from './Deduction';
import FormDialog from '../Dialog/FormDialog';
import AddOrEditDeductionForm from './AddOrEditDeductionForm';
import DeleteDeductionForm from './DeleteDeductionForm';
import DeductionsTable from './DeductionsTable';
import { Grid } from '@material-ui/core';
import { Category } from '../Categories/Category';
import { AddOrEditCategoryRequestBody } from '../../store/categories/types';
import AddOrEditCategoryForm from '../Categories/AddOrEditCategoryForm';
import DeleteCategoryForm from '../Categories/DeleteCategoryForm';
import CategoryList from '../Categories/CategoryList';
import moment from 'moment';
import DurationPicker from '../Duration/DurationPicker';
import { currentMonthStartDate, currentMonthEndDate } from '../Duration/DurationConfig';
import { convertDateToApiParamString, isWithInRange } from '../../utils/dateUtils';

const mapStateToProps = (state: RootState) => ({
    deductions: state.deductions,
    deductionsCategories: state.categories.deductions,
    defaultDeductionCategoryId: state.settings.preferences.defaultDeductionCategory,
    defaultAccountId: state.settings.preferences.defaultDeductionsAccount,
    accounts: state.accounts
})
const mapDispatchToProps = {
    fetchAllDeductions, addDeduction,
    editDeduction, removeDeduction,
    fetchAllDeductionsCategories, addDeductionsCategory,
    editDeductionsCategory, removeDeductionsCategory,
    fetchAllAccounts
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type DeductionsProps = ConnectedProps<typeof connector>
type DeductionsFormType = 'add_deduction' | 'edit_deduction' | 'delete_deduction'
type DeductionsCategoryFormType = 'add_deductions_category' | 'edit_deductions_category' | 'delete_deductions_category'
type FormType = DeductionsFormType | DeductionsCategoryFormType
type SelectedDeduction = Deduction | null
type SelectedDeductionCategory = Category | null
type FormStatus = {
    formTitle: string
    formType: FormType | null
    selectedDeduction: SelectedDeduction
    selectedDeductionCategory: SelectedDeductionCategory
}
type DeductionsState = {
    formStatus: FormStatus
}

class Deductions extends Component<DeductionsProps, DeductionsState> {
    startDate: Date = currentMonthStartDate;
    endDate: Date = currentMonthEndDate;

    constructor(props: DeductionsProps) {
        super(props)
        this.state = {
            formStatus: {
                formTitle: "",
                formType: null,
                selectedDeduction: null,
                selectedDeductionCategory: null
            }
        }
    }

    componentDidMount(): void {
        this.props.fetchAllDeductions({
            startDate: convertDateToApiParamString(currentMonthStartDate),
            endDate: convertDateToApiParamString(currentMonthEndDate)
        })
        this.props.fetchAllDeductionsCategories()
        this.props.fetchAllAccounts()
    }

    getDefaultDeduction = (): Deduction => {
        return {
            id: '', name: '', date: new Date().toISOString(), amount: 0, deductionCategoryId: this.props.defaultDeductionCategoryId,
            accountId: this.props.defaultAccountId
        }
    }

    setDeductionsFormStatus = (formTitle: string, formType: DeductionsFormType | null, selectedDeduction: SelectedDeduction = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedDeduction, selectedDeductionCategory: null
            }
        })
    }

    setDeductionsCategoryFormStatus = (formTitle: string, formType: DeductionsCategoryFormType | null, selectedDeductionCategory: SelectedDeductionCategory = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedDeductionCategory, selectedDeduction: null
            }
        })
    }

    closeForm = () => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle: "",
                formType: null,
                selectedDeduction: null,
                selectedDeductionCategory: null
            }
        })
    }

    renderFormDialog = () => {
        if (this.state.formStatus.formType) {
            let formComponent = null

            switch (this.state.formStatus.formType) {
                case 'add_deduction': {
                    const submitForm = (formValues: AddOrEditDeductionRequestBody) => {
                        this.closeForm()
                        this.props.addDeduction(formValues)
                    }
                    formComponent = <AddOrEditDeductionForm
                        deduction={this.getDefaultDeduction()}
                        accounts={this.props.accounts}
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                        deductionsCategories={this.props.deductionsCategories}
                    />
                    break
                }
                case 'edit_deduction': {
                    if (this.state.formStatus.selectedDeduction) {
                        const id = this.state.formStatus.selectedDeduction.id
                        const submitForm = (formValues: AddOrEditDeductionRequestBody) => {
                            this.closeForm()
                            this.props.editDeduction(id, formValues)
                        }
                        formComponent = <AddOrEditDeductionForm
                            accounts={this.props.accounts}
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            deductionsCategories={this.props.deductionsCategories}
                            deduction={this.state.formStatus.selectedDeduction}
                        />
                    }
                    break
                }
                case 'delete_deduction': {
                    if (this.state.formStatus.selectedDeduction) {
                        const id = this.state.formStatus.selectedDeduction.id
                        const submitForm = () => {
                            this.closeForm()
                            this.props.removeDeduction(id)
                        }
                        formComponent = <DeleteDeductionForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            deduction={this.state.formStatus.selectedDeduction}
                        />
                    }
                    break
                }
                case 'add_deductions_category': {
                    const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                        this.closeForm()
                        this.props.addDeductionsCategory(formValues)
                    }
                    formComponent = <AddOrEditCategoryForm
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                    />
                    break
                }
                case 'edit_deductions_category': {
                    if (this.state.formStatus.selectedDeductionCategory) {
                        const id = this.state.formStatus.selectedDeductionCategory.id
                        const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                            this.closeForm()
                            this.props.editDeductionsCategory(id, formValues)
                        }
                        formComponent = <AddOrEditCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedDeductionCategory}
                        />
                    }
                    break
                }
                case 'delete_deductions_category': {
                    if (this.state.formStatus.selectedDeductionCategory) {
                        const id = this.state.formStatus.selectedDeductionCategory.id
                        const submitForm = () => {
                            this.closeForm()
                            this.props.removeDeductionsCategory(id)
                        }
                        formComponent = <DeleteCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedDeductionCategory}
                        />
                    }
                    break
                }
            }

            return (
                <FormDialog
                    title={this.state.formStatus.formTitle}
                    closeDialog={this.closeForm}
                    open
                >
                    {formComponent}
                </FormDialog>
            )
        }

        return null
    }

    onDurationSelect = (range: Date[]) => {
        this.startDate = range[0];
        this.endDate = range[1];
        
        this.props.fetchAllDeductions({
            startDate: convertDateToApiParamString(range[0]),
            endDate: convertDateToApiParamString(range[1])
        })
    }

    render() {
        const deductions = _.filter(this.props.deductions, e => isWithInRange(new Date(e.date), this.startDate, this.endDate))

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DurationPicker onSelect={this.onDurationSelect} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DeductionsTable
                            deductions={deductions}
                            deductionsCategories={this.props.deductionsCategories}
                            accounts={this.props.accounts}
                            addDeduction={() => this.setDeductionsFormStatus("Add Deduction", "add_deduction")}
                            editDeduction={(saving: Deduction) => this.setDeductionsFormStatus("Edit Deduction", "edit_deduction", saving)}
                            deleteDeduction={(saving: Deduction) => this.setDeductionsFormStatus("Delete Deduction", "delete_deduction", saving)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CategoryList
                            title={'Deduction Categories'}
                            categories={this.props.deductionsCategories}
                            addCategory={() => this.setDeductionsCategoryFormStatus('Add Deduction Category', 'add_deductions_category')}
                            editCategory={(c: Category) => this.setDeductionsCategoryFormStatus('Edit Deduction Category', 'edit_deductions_category', c)}
                            deleteCategory={(c: Category) => this.setDeductionsCategoryFormStatus('Delete Deduction Category', 'delete_deductions_category', c)}
                        />
                    </Grid>
                </Grid>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default connector(Deductions)