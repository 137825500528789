import React from 'react';
import { Formik, Form, Field, FormikErrors, FormikValues, ErrorMessage } from 'formik';
import { Income, AddOrEditIncomeRequestBody } from "./Income";
import { TextField, Select } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Category } from '../Categories/Category';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Account } from '../Accounts/Account';

interface AddOrEditIncomeFormValues {
    name: string
    amount: number | ''
    incomeSourceId: string
    date: string
    accountId: string | null
}

type AddOrEditIncomeFormProps = {
    income?: Income | null
    incomeSources: Category[]
    accounts: Account[]
    submitForm: (formValues: AddOrEditIncomeRequestBody) => void
    closeForm: () => void
}

const AddOrEditIncomeForm = (props: AddOrEditIncomeFormProps) => {
    const initialValues: AddOrEditIncomeFormValues = {
        name: props.income ? props.income.name : '',
        amount: (props.income && props.income.amount) ? props.income.amount : '',
        incomeSourceId: props.income && props.income.incomeSourceId ? props.income.incomeSourceId : '-1',
        date: props.income ? new Date(props.income.date).toISOString() : new Date().toISOString(),
        accountId: props.income && props.income.accountId ? props.income.accountId : "-1"
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: FormikErrors<FormikValues> = {}
                    if (!values.name || (!values.name.trim())) {
                        errors.name = "Name is required"
                    }
                    if (values.incomeSourceId === "-1") {
                        errors.incomeSourceId = "Source of income is required"
                    }
                    if (!values.amount || (values.amount <= 0)) {
                        errors.amount = "Amount should be > 0"
                    }
                    if (!values.date) {
                        errors.date = "Date is required"
                    }

                    return errors
                }}
                onSubmit={(values) => {
                    const formValues = {...values}
                    if (formValues.accountId === "-1") {
                        formValues.accountId = null
                    }

                    props.submitForm(formValues)
                }}
            >
                {({ isValid, touched, errors, dirty, submitForm }) => (
                    <Form>
                        <FormControl fullWidth>
                            <Field
                                autoFocus
                                className={'Form-input'}
                                component={TextField}
                                name="name"
                                label="Name"
                                required
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field
                                className={'Form-input'}
                                component={TextField}
                                name="amount"
                                label="Amount"
                                required
                                type="number"
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field inputProps={{autocomplete: "off"}} required format="dd MMM yyyy" component={DatePicker} label="Date" name="date" />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel error={Boolean(errors.incomeSourceId && touched.incomeSourceId)} required htmlFor="source-of-income">Source of Income</InputLabel>
                            <Field
                                error={errors.incomeSourceId && touched.incomeSourceId}
                                className={'Form-input'}
                                component={Select}
                                name="incomeSourceId"
                                inputProps={{
                                    id: 'source-of-income',
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.incomeSources.map((c: Category) => (
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="incomeSourceId" render={(msg: string) => <p className="MuiFormHelperText-root Mui-error Mui-required">{msg}</p>}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel variant={'filled'} htmlFor="account-id">Account</InputLabel>
                            <Field
                                className={'Form-input'}
                                component={Select}
                                name="accountId"
                                inputProps={{
                                    id: 'account-id',
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.accounts.map((p: Account) => (
                                        <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                                    ))
                                }
                            </Field>
                        </FormControl>
                        <div className="Form-actions">
                            <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="secondary">
                                Cancel
                            </Button>
                            <Button disabled={!dirty || !isValid} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
}

export default AddOrEditIncomeForm