import apiClient from "./apiClient";
import { AddOrEditAccountRequestBody } from "../components/Accounts/Account";

export const getAllAccounts = () => apiClient.get("/accounts")
export const createAccount = (requestBody: AddOrEditAccountRequestBody) => (
    apiClient.post("/accounts", requestBody)
)
export const updateAccount = (id: string, requestBody: AddOrEditAccountRequestBody) => (
    apiClient.put(`/accounts/${id}`, requestBody)
)
export const deleteAccount = (id: string) => apiClient.delete(`/accounts/${id}`)