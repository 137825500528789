import React from "react";
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { IconButton } from "@material-ui/core";

interface DownloadCSVButtonProps {
    data: any,
    fileName: string
}

const DownloadCSVButton = (props: DownloadCSVButtonProps) => {
    return(
        <CSVLink data={props.data} filename={props.fileName} target="_blank">
            <IconButton>
                <CloudDownloadIcon/>
            </IconButton>
        </CSVLink>
    )
}

export default DownloadCSVButton