import React, { useState } from "react";
import { currentMonthStartDate, currentMonthEndDate } from "../../Duration/DurationConfig";
import { Grid } from "@material-ui/core";
import DurationPricker from "../../Duration/DurationPicker";
import ExpensesByCategoryTab from "./ExpensesByCategoryTab";

const ExpensesByCategory = () => {
    const [dateRange, setDateRange] = useState({
        startDate: currentMonthStartDate,
        endDate: currentMonthEndDate
    })
    const onDurationSelect = (range: Date[]) => {
        setDateRange({
            startDate: range[0],
            endDate: range[1]
        })
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DurationPricker
                    onSelect={onDurationSelect}
                    fromDate={dateRange.startDate}
                    toDate={dateRange.endDate}
                />
            </Grid>
            <Grid item xs={12}>
                <ExpensesByCategoryTab startDate={dateRange.startDate} endDate={dateRange.endDate}/>
            </Grid>
        </Grid>
    )
}

export default ExpensesByCategory