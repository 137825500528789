import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { fetchPreferences, editPreferences } from "../../../store/settings/actions";
import {
    fetchAllExpensesCategories, fetchAllDeductionsCategories,
    fetchAllIncomeSources, fetchAllSavingsCategories
} from "../../../store/categories/actions";
import { fetchAllAccounts } from "../../../store/accounts/actions";
import { RootState } from "../../../store";
import PreferencesForm from "./PreferencesForm";
import { Preferences as IPreferences } from "./Preferences";

const mapStateToProps = (state: RootState) => ({
    preferences: state.settings.preferences,
    expensesCategories: state.categories.expenses,
    incomeSources: state.categories.incomeSources,
    deductionsCategories: state.categories.deductions,
    savingsCategories: state.categories.savings,
    accounts: state.accounts
})
const mapDispatchToProps = {
    fetchPreferences, editPreferences,
    fetchAllExpensesCategories, fetchAllDeductionsCategories,
    fetchAllSavingsCategories, fetchAllIncomeSources,
    fetchAllAccounts
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type PreferencesProps = ConnectedProps<typeof connector>

class Preferences extends Component<PreferencesProps, {}> {
    componentDidMount() {
        this.props.fetchPreferences()
        this.props.fetchAllExpensesCategories()
        this.props.fetchAllDeductionsCategories()
        this.props.fetchAllSavingsCategories()
        this.props.fetchAllIncomeSources()
        this.props.fetchAllAccounts()
    }

    submitForm = (preferences: IPreferences) => this.props.editPreferences(preferences)
    
    render() {
        return(
            <Card>
                <CardHeader title="Preferences"/>
                <CardContent>
                    <PreferencesForm
                        expensesCategories={this.props.expensesCategories}
                        savingsCategories={this.props.savingsCategories}
                        deductionsCategories={this.props.deductionsCategories}
                        incomeSources={this.props.incomeSources}
                        preferences={this.props.preferences}
                        accounts={this.props.accounts}
                        submitForm={this.submitForm}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default connector(Preferences)