import apiClient from "./apiClient";
import { AddOrEditCategoryRequestBody } from "../store/categories/types";

export const getAllExpensesCategory = () => apiClient.get("/category/all")
export const getAllSavingsCategory = () => apiClient.get("/savingsCategory")
export const getAllIncomeSources = () => apiClient.get("/incomeSource")
export const getAllDeductionsCategory = () => apiClient.get("/deductionCategory")

export const createExpensesCategory = (requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.post("/category", requestBody)
)
export const createSavingsCategory = (requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.post("/savingsCategory", requestBody)
)
export const createIncomeSource = (requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.post("/incomeSource", requestBody)
)
export const createDeductionsCategory = (requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.post("/deductionCategory", requestBody)
)

export const updateExpensesCategory = (id: string, requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.put(`/category/${id}`, requestBody)
)
export const updateSavingsCategory = (id: string, requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.put(`/savingsCategory/${id}`, requestBody)
)
export const updateIncomeSource = (id: string, requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.put(`/incomeSource/${id}`, requestBody)
)
export const updateDeductionsCategory = (id: string, requestBody: AddOrEditCategoryRequestBody) => (
    apiClient.put(`/deductionCategory/${id}`, requestBody)
)

export const deleteExpensesCategory = (id: string) => apiClient.delete(`/category/${id}`)
export const deleteSavingsCategory = (id: string) => apiClient.delete(`/savingsCategory/${id}`)
export const deleteIncomeSource = (id: string) => apiClient.delete(`/incomeSource/${id}`)
export const deleteDeductionsCategory = (id: string) => apiClient.delete(`/deductionCategory/${id}`)