import React, { Component } from "react";
import { Card, CardHeader, Tooltip, IconButton, CardContent, ExpansionPanel, ExpansionPanelSummary, Grid, Box, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Expense, AddOrEditExpenseRequestBody } from "../Expense";
import { getRecentExpenses, updateExpense, createExpense, deleteExpense } from "../../../services/expensesService";
import RecentExpensesListItem from "./RecentExpensesListItem";
import RecentExpensesListItemDetails from "./RecentExpensesListItemDetails";
import { Category } from "../../Categories/Category";
import _ from "lodash";
import { getAllExpensesCategory } from "../../../services/categoriesService";
import AddOrEditExpenseForm from "../AddOrEditExpenseForm";
import DeleteExpenseForm from "../DeleteExpenseForm";
import FormDialog from "../../Dialog/FormDialog";
import { Account } from "../../Accounts/Account";
import { getAllAccounts } from "../../../services/accountsService";
import { getPreferences } from "../../../services/settingsService";
import { Link } from "react-router-dom";

interface RecentExpensesListProps { }
interface RecentExpensesListState {
    expenses: Expense[]
    expensesCategories: Category[]
    accounts: Account[]
    expandedExpenseId: string
    selectedExpense: Expense | null
    formType: 'add_expense' | 'edit_expense' | 'delete_expense' | null,
    formTitle: string,
    loaded: boolean
    defaultExpenseCategory: string
    defaultExpenseSubCategory: string
    defaultExpenseAccount: string
    defaultPaymentType: string
}

class RecentExpensesList extends Component<RecentExpensesListProps, RecentExpensesListState> {
    constructor(props: RecentExpensesListProps) {
        super(props)
        this.state = {
            expenses: [],
            expensesCategories: [],
            accounts: [],
            expandedExpenseId: "",
            selectedExpense: null,
            formType: null,
            formTitle: "",
            loaded: false,
            defaultExpenseAccount: "",
            defaultPaymentType: "",
            defaultExpenseCategory: "",
            defaultExpenseSubCategory: ""
        }
    }

    componentDidMount() {
        this.fetchExpensesCategories()
        this.fetchRecentExpenses()
        this.fetchAccounts()
        this.fetchPreferences()
    }

    getDefaultExpense = (): Expense => {
        return {
            id: '', name: '', date: new Date().toISOString(), amount: 0, categoryId: this.state.defaultExpenseCategory,
            categoryName: '', subCategoryId: this.state.defaultExpenseSubCategory, subCategoryName: '',
            paymentType: this.state.defaultPaymentType, accountId: this.state.defaultExpenseAccount
        }
    }

    fetchExpensesCategories = async () => {
        try {
            const response = await getAllExpensesCategory()

            this.setState({
                expensesCategories: response.data.data
            })
        } catch (e) {
            console.log(e)
        }
    }

    fetchRecentExpenses = async () => {
        try {
            const response = await getRecentExpenses({
                limit: 5
            })

            this.setState({
                expenses: response.data.data,
                loaded: true
            })
        } catch (e) {
            console.log(e)
        }
    }

    fetchPreferences = async () => {
        try {
            const response = await getPreferences()
            const preferences = response.data.data

            this.setState({
                defaultExpenseAccount: preferences.defaultExpenseAccount || "",
                defaultExpenseCategory: preferences.defaultExpenseCategory || "",
                defaultExpenseSubCategory: preferences.defaultExpenseSubCategory || "",
                defaultPaymentType: preferences.defaultPaymentType || ""
            })
        } catch(e) {
            console.log(e)
        }
    }

    fetchAccounts = async () => {
        try {
            const response = await getAllAccounts()

            this.setState({
                accounts: response.data.data
            })
        } catch(e) {
            console.log(e)
        }
    }

    closeForm = () => {
        this.setState({
            formTitle: "",
            formType: null,
            selectedExpense: null
        })
    }

    openAddExpenseForm = () => {
        this.setState({
            formTitle: "Add Expense",
            formType: "add_expense"
        })
    }

    openEditExpenseForm = (expense: Expense) => {
        this.setState({
            formTitle: "Edit Expense",
            formType: "edit_expense",
            selectedExpense: expense
        })
    }

    openDeleteExpenseForm = (expense: Expense) => {
        this.setState({
            formTitle: "Delete Expense",
            formType: "delete_expense",
            selectedExpense: expense
        })
    }

    submitAddExpenseForm = async (formValues: AddOrEditExpenseRequestBody) => {
        this.closeForm()
        try {
            await createExpense(formValues)
            this.fetchRecentExpenses()
        } catch (e) {
            console.log(e)
        }
    }

    submitEditExpenseForm = async (formValues: AddOrEditExpenseRequestBody) => {
        try {
            if (this.state.selectedExpense) {
                const id = this.state.selectedExpense.id
                this.closeForm()
                await updateExpense(id, formValues)
                this.fetchRecentExpenses()
            }
        } catch (e) {
            console.log(e)
        }
    }

    submitDeleteExpenseForm = async () => {
        try {
            if (this.state.selectedExpense) {
                const id = this.state.selectedExpense.id
                this.closeForm()
                await deleteExpense(id)
                this.fetchRecentExpenses()
            }
        } catch (e) {
            console.log(e)
        }
    }

    renderFormDialog = () => {
        if (this.state.formType) {
            let formComponent = null

            switch (this.state.formType) {
                case 'add_expense': {
                    formComponent = <AddOrEditExpenseForm
                        expense={this.getDefaultExpense()}
                        accounts={this.state.accounts}
                        submitForm={this.submitAddExpenseForm}
                        closeForm={this.closeForm}
                        expensesCategories={this.state.expensesCategories}
                    />
                    break
                }
                case 'edit_expense': {
                    formComponent = <AddOrEditExpenseForm
                        accounts={this.state.accounts}
                        submitForm={this.submitEditExpenseForm}
                        closeForm={this.closeForm}
                        expensesCategories={this.state.expensesCategories}
                        expense={this.state.selectedExpense}
                    />
                    break
                }
                case 'delete_expense': {
                    if (this.state.selectedExpense) {
                        formComponent = <DeleteExpenseForm
                            submitForm={this.submitDeleteExpenseForm}
                            closeForm={this.closeForm}
                            expense={this.state.selectedExpense}
                        />
                    }
                    break
                }
            }

            return (
                <FormDialog
                    title={this.state.formTitle}
                    closeDialog={this.closeForm}
                    open
                >
                    {formComponent}
                </FormDialog>
            )
        }

        return null
    }

    renderAddNewExpenseActionBtn = () => {
        return (
            <Tooltip title={'Add Expense'}>
                <IconButton onClick={this.openAddExpenseForm}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        )
    }

    renderExpansionPanelSummary = (expense: Expense) => {
        return (
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={expense.name}
                id={expense.id}
            >
                <RecentExpensesListItem
                    item={expense}
                />
            </ExpansionPanelSummary>
        )
    }

    handleExpansion = (panelId: string) => (event: any, isExpanded: boolean) => {
        this.setState({
            expandedExpenseId: isExpanded ? panelId : ""
        })
    };

    renderRecentExpensesList = () => {
        if (!this.state.loaded) {
            return <Box textAlign="center"><CircularProgress /></Box>
        }

        if (!this.state.expenses.length) {
            return <Box textAlign="center">No Data</Box>
        }
        
        const expensesList = this.state.expenses.map((expense: Expense) => {
            const expandPanel: boolean = Boolean((this.state.expandedExpenseId === expense.id))
            const category = _.find(this.state.expensesCategories, (c) => c.id === expense.categoryId)
            const account = _.find(this.state.accounts, (a) => a.id === expense.accountId)
            if (category) {
                if (expense.subCategoryId) {
                    const subCategory = _.find(category.subCategories, (c) => c.id === expense.subCategoryId)
                    expense.subCategoryName = subCategory ? subCategory.name : "NA"
                }
            }
            expense.categoryName = category ? category.name : "NA"
            expense.accountName = account ? account.name : "NA"

            return (
                <ExpansionPanel
                    elevation={0}
                    key={expense.id}
                    expanded={expandPanel}
                    onChange={this.handleExpansion(expense.id)}
                >
                    {this.renderExpansionPanelSummary(expense)}
                    <RecentExpensesListItemDetails
                        editExpense={this.openEditExpenseForm}
                        deleteExpense={this.openDeleteExpenseForm}
                        expense={expense}
                    />
                </ExpansionPanel>
            )
        })

        return(
            <React.Fragment>
                {expensesList}
                <Box textAlign="right">
                    <Link to="/expenses">View All</Link>
                </Box>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Card style={{height: "100%"}}>
                    <CardHeader title={'Recent Expenses'} action={this.renderAddNewExpenseActionBtn()} />
                    <CardContent>{this.renderRecentExpensesList()}</CardContent>
                </Card>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default RecentExpensesList