import React, {memo, useContext } from "react";
import { getHtmlEntityFor } from "../../../utils/currencyUtils";
import CurrencyContext from "../../../context/CurrencyContext";

type AmountProps = {
    value: any
}

const Amount = (props: AmountProps) => {
    const currencyContext = useContext(CurrencyContext)
    const currency = currencyContext.currency

    return (
        <span dangerouslySetInnerHTML={{ __html: `${getHtmlEntityFor(currency)} ${props.value.toLocaleString()}` }} />
    )
}

export default memo(Amount)