import apiClient from './apiClient'
import { AddOrEditSavingsRequestBody } from '../components/Savings/Saving'

export const getAllSavings = (params?: any) => apiClient.get("/savings", {params})
export const createSavings = (requestBody: AddOrEditSavingsRequestBody) => (
    apiClient.post("/savings", requestBody)
)
export const updateSavings = (id: string, requestBody: AddOrEditSavingsRequestBody) => (
    apiClient.put(`/savings/${id}`, requestBody)
)
export const deleteSavings = (id: string) => apiClient.delete(`/savings/${id}`)