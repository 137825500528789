import { batch } from "react-redux";
import { AppThunk } from "..";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import { FETCH_ALL_SAVINGS, REMOVE_SAVING, ADD_SAVING, EDIT_SAVING } from "./types";
import { AddOrEditSavingsRequestBody } from "../../components/Savings/Saving";
import { createSavings, updateSavings, deleteSavings, getAllSavings } from "../../services/savingsService";

export const fetchAllSavings = (params?: any): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllSavings(params)

        batch(() => {
            dispatch({
                type: FETCH_ALL_SAVINGS,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addSavings = (requestBody: AddOrEditSavingsRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createSavings(requestBody)

        batch(() => {
            dispatch({
                type: ADD_SAVING,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Savings added successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editSavings = (id: string, requestBody: AddOrEditSavingsRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateSavings(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_SAVING,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Savings updated successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeSavings = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteSavings(id)

        batch(() => {
            dispatch({
                type: REMOVE_SAVING,
                payload: {id}
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Savings removed successfully"))
        })
    } catch(e) {
        dispatch(hidePageLoader())
        dispatch(showApiError(e))
    }
}