import { batch } from "react-redux";
import { AppThunk } from "..";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import { FETCH_ALL_INCOMES, REMOVE_INCOME, ADD_INCOME, EDIT_INCOME } from "./types";
import { AddOrEditIncomeRequestBody } from "../../components/Incomes/Income";
import { createIncome, updateIncome, deleteIncome, getAllIncomes } from "../../services/incomesService";

export const fetchAllIncomes = (params?: any): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllIncomes(params)

        batch(() => {
            dispatch({
                type: FETCH_ALL_INCOMES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addIncome = (requestBody: AddOrEditIncomeRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createIncome(requestBody)

        batch(() => {
            dispatch({
                type: ADD_INCOME,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Income added successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editIncome = (id: string, requestBody: AddOrEditIncomeRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateIncome(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_INCOME,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeIncome = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteIncome(id)

        batch(() => {
            dispatch({
                type: REMOVE_INCOME,
                payload: {id}
            })
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}