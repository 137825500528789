import React from "react";
import { Card, CardContent } from "@material-ui/core";

interface StatsItemProps {
    header: any
    icon: any
    subHeader: any
}

const StatsItem = (props: StatsItemProps) => {
    return(
        <Card elevation={4}>
            <CardContent style={{textAlign: "center"}}>
                {props.icon}
                <h2>
                {props.header}
                    </h2>
                {props.subHeader}
            </CardContent>
        </Card>
    )
}

export default StatsItem