import React from 'react';
import _ from "lodash";
import { Formik, Form, Field, FormikErrors, FormikValues, ErrorMessage } from 'formik';
import { Expense, AddOrEditExpenseRequestBody } from "./Expense";
import { TextField, Select } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Category } from '../Categories/Category';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Account } from '../Accounts/Account';

interface AddOrEditExpenseFormValues {
    name: string
    amount: number | ''
    categoryId: string
    subCategoryId: string | null
    date: string
    paymentType: string
    accountId: string | null
}

type AddOrEditExpenseFormProps = {
    expense?: Expense | null
    expensesCategories: Category[]
    accounts: Account[]
    submitForm: (formValues: AddOrEditExpenseRequestBody) => void
    closeForm: () => void
}

type PaymentType = {
    title: string
    code: string
}

const paymentTypes: PaymentType[] = [
    { title: "Cash", code: "CASH" },
    { title: "Online Banking", code: "ONLINE_BANKING" },
    { title: "Debit Card", code: "DEBIT_CARD" },
    { title: "Credit Card", code: "CREDIT_CARD" },
    { title: "PayTM", code: "PAYTM" },
    { title: "UPI", code: "UPI" }
]

const AddOrEditExpenseForm = (props: AddOrEditExpenseFormProps) => {
    const initialValues: AddOrEditExpenseFormValues = {
        name: props.expense ? props.expense.name : '',
        amount: (props.expense && props.expense.amount) ? props.expense.amount : '',
        categoryId: props.expense && props.expense.categoryId ? props.expense.categoryId : '-1',
        subCategoryId: (props.expense && props.expense.subCategoryId) ? props.expense.subCategoryId : '-1',
        paymentType: props.expense && props.expense.paymentType ? props.expense.paymentType : '-1',
        date: props.expense ? new Date(props.expense.date).toISOString() : new Date().toISOString(),
        accountId: (props.expense && props.expense.accountId) ? props.expense.accountId : "-1"
    }

    const renderSubCategories = (categoryId: string) => {
        let subCategories: Category[] = [];
        if (categoryId) {
            const category = _.find(props.expensesCategories, (e) => e.id === categoryId)
            if (category && category.subCategories) {
                subCategories = category.subCategories;
            }
        }

        return (
            <FormControl fullWidth>
                <InputLabel variant={'filled'} htmlFor="sub-category">Sub Category</InputLabel>
                <Field
                    disabled={!categoryId}
                    className={'Form-input'}
                    component={Select}
                    name="subCategoryId"
                    inputProps={{
                        id: "sub-category",
                        autocomplete: "off"
                    }}
                >
                    <MenuItem key={'-1'} value={'-1'}>None</MenuItem>
                    {
                        subCategories.map((c: Category) => (
                            <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                        ))
                    }
                </Field>
            </FormControl>
        )
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: FormikErrors<FormikValues> = {}
                    if (!values.name || (!values.name.trim())) {
                        errors.name = "Name is required"
                    }
                    if (values.categoryId === "-1") {
                        errors.categoryId = "Category is required"
                    }
                    if (values.paymentType === "-1") {
                        errors.paymentType = "Payment Type is required"
                    }
                    if (!values.amount || (values.amount <= 0)) {
                        errors.amount = "Amount should be > 0"
                    }
                    if (!values.date) {
                        errors.date = "Date is required"
                    }

                    return errors
                }}
                onSubmit={(values) => {
                    const formValues = {...values}
                    if (formValues.subCategoryId === '-1') {
                        formValues.subCategoryId = null
                    }
                    if (formValues.accountId === "-1") {
                        formValues.accountId = null
                    }

                    props.submitForm(formValues)
                }}
            >
                {({ values, isValid, touched, errors, dirty, submitForm }) => (
                    <Form>
                        <FormControl fullWidth>
                            <Field
                                autoFocus
                                className={'Form-input'}
                                component={TextField}
                                name="name"
                                label="Name"
                                required
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field
                                className={'Form-input'}
                                component={TextField}
                                name="amount"
                                label="Amount"
                                required
                                type="number"
                                inputProps={{
                                    autocomplete: "off"
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field inputProps={{autocomplete: "off"}} required format="dd MMM yyyy" component={DatePicker} label="Date" name="date" />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel error={Boolean(errors.categoryId && touched.categoryId)} required variant={'filled'} htmlFor="category">Category</InputLabel>
                            <Field
                                error={errors.categoryId && touched.categoryId}
                                className={'Form-input'}
                                component={Select}
                                name="categoryId"
                                inputProps={{
                                    id: "category",
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.expensesCategories.map((c: Category) => (
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="categoryId" render={(msg: string) => <p className="MuiFormHelperText-root Mui-error Mui-required">{msg}</p>} />
                        </FormControl>
                        {renderSubCategories(values.categoryId)}
                        <FormControl fullWidth>
                            <InputLabel error={Boolean(errors.paymentType && touched.paymentType)} required variant={'filled'} htmlFor="payment-type">Payment Type</InputLabel>
                            <Field
                                error={errors.paymentType && touched.paymentType}
                                required
                                className={'Form-input'}
                                component={Select}
                                name="paymentType"
                                inputProps={{
                                    id: 'payment-type',
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    paymentTypes.map((p: PaymentType) => (
                                        <MenuItem key={p.code} value={p.code}>{p.title}</MenuItem>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="paymentType" render={(msg: string) => <p className="MuiFormHelperText-root Mui-error Mui-required">{msg}</p>} />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel variant={'filled'} htmlFor="account-id">Account</InputLabel>
                            <Field
                                className={'Form-input'}
                                component={Select}
                                name="accountId"
                                inputProps={{
                                    id: 'account-id',
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem key="-1" value="-1">None</MenuItem>
                                {
                                    props.accounts.map((p: Account) => (
                                        <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                                    ))
                                }
                            </Field>
                        </FormControl>
                        <div className="Form-actions">
                            <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="secondary">
                                Cancel
                            </Button>
                            <Button disabled={!dirty || !isValid} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
}

export default AddOrEditExpenseForm