import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Account } from "./Account";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Avatar, IconButton, Tooltip, Box } from "@material-ui/core";
import Amount from "../App/Currency/Amount";

interface AccountListProps {
    accounts: Account[]
    addAccount: () => void
    editAccount: (account: Account) => void
    deleteAccount: (account: Account) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        padding: theme.spacing(2),
        fontSize: "16px",
        display: "flex"
    },
    header: {
        flexGrow: 1
    }
}))

const AccountList = (props: AccountListProps) => {
    const classes = useStyles()

    const renderTable = () => {
        if (!props.accounts.length) {
            return(
                <Box textAlign="center">
                    <h3>No Data</h3>
                </Box>
            )
        }

        return(
            <Table area-label="accounts list">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell variant="head">Name</TableCell>
                        <TableCell variant="head" align="right">Balance</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.accounts.map((account: Account) => (
                        <TableRow key={account.id}>
                            <TableCell><Avatar>{account.name.substr(0, 1)}</Avatar></TableCell>
                            <TableCell>{account.name}</TableCell>
                            <TableCell align="right"><Amount value={account.balance} /></TableCell>
                            <TableCell align="right">
                                <Tooltip title="Edit Account">
                                    <IconButton size={"small"} onClick={() => props.editAccount(account)}>
                                        <EditIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Account">
                                    <IconButton size={"small"} onClick={() => props.deleteAccount(account)}>
                                        <DeleteIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return (
        <TableContainer component={Paper}>
            <div className={classes.headerContainer}>
                <p className={classes.header}>Accounts</p>
                <Tooltip title="Add New Account">
                    <IconButton onClick={props.addAccount}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {renderTable()}
        </TableContainer>
    )
}

export default AccountList