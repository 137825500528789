import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Expense } from '../Expense';
import { Grid } from '@material-ui/core';
import Amount from '../../App/Currency/Amount';
import Moment from 'react-moment';

type RecentExpensesListItemProps = {
    item: Expense
}

const RecentExpensesListItem = (props: RecentExpensesListItemProps) => {
    return (
        <Grid container>
            <Grid item xs={6}>
                {props.item.name}
            </Grid>
            <Grid item xs={3}>
                <Moment format="DD MMM">{props.item.date}</Moment>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
                <Amount value={props.item.amount} />
            </Grid>
        </Grid>
    )
}

export default RecentExpensesListItem