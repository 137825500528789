import moment from "moment";

export const todaysDate: Date = new Date(moment().startOf('day').format())
export const currentYearStartDate: Date = new Date(moment().startOf('year').startOf('day').format())
export const currentYearEndDate: Date = new Date(moment().endOf('year').endOf('day').format())
export const currentMonthStartDate: Date = new Date(moment().startOf('month').startOf('day').format())
export const currentMonthEndDate: Date = new Date(moment().endOf('month').endOf('day').format())
export const lastWeekStartDate: Date = new Date(moment().subtract(1, 'week').startOf('day').format())
export const last3MonthsStartDate: Date = new Date(moment().startOf('month').subtract(2, 'months').startOf('day').format())
export const last6MonthsStartDate: Date = new Date(moment().startOf('month').subtract(5, 'months').startOf('day').format())
export const last12MonthsStartDate: Date = new Date(moment().startOf('month').subtract(11, 'months').startOf('day').format())