import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ExpensesTrendTab from "../Analytics/ExpensesTrend/ExpensesTrendTab";
import { currentMonthEndDate, lastWeekStartDate, last3MonthsStartDate } from "../Duration/DurationConfig";
import ExpensesByCategoryTab from "../Analytics/ExpensesByCategory/ExpensesByCategoryTab";
import RecentExpensesList from "../Expenses/RecentExpensesTab";

const Dashboard = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <RecentExpensesList />
            </Grid>
            <Grid item xs={12} md={6}>
                <ExpensesTrendTab
                    dailyExpenseTrendChartTitle="Last 7 Days Expenditure"
                    dailyExpenditureStartDate={lastWeekStartDate}
                    dailyExpenditureEndDate={currentMonthEndDate}
                    monthlyExpenseTrendChartTitle="Last 12 Months Expenditure"
                    monthlyExpenditureStartDate={last3MonthsStartDate}
                    monthlyExpenditureEndDate={currentMonthEndDate}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ExpensesByCategoryTab startDate={lastWeekStartDate} endDate={currentMonthEndDate} />
            </Grid>
        </Grid>
    )
}

export default Dashboard