import React, { useEffect } from 'react';
import _ from "lodash";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PieChartIcon from '@material-ui/icons/PieChart';
import ListIcon from '@material-ui/icons/List';
import TabPanel from '../../Tabs/TabPanel';
import SimplePieChart from '../../Charts/SimplePieChart';
import SavingsByCategoryList from './SavingsByCategoryList';
import { getSavingsByCategory } from '../../../services/analyticsService';
import { convertDateToApiParamString } from '../../../utils/dateUtils';
import { SavingsByCategory } from '../Analytics';
import ChartHeader from '../../Charts/ChartHeader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface SavingsByCategoryTabProps {
    startDate: Date
    endDate: Date
}

const fetchSavingsByCategory = async (startDate: Date, endDate: Date) => {
    const response = await getSavingsByCategory({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}

const SavingsByCategoryTab = (props: SavingsByCategoryTabProps) => {
    const classes = useStyles();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [pieChartData, setPieChartData] = React.useState([]);
    const [savings, setSavings] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        setLoaded(false)
        
        fetchSavingsByCategory(props.startDate, props.endDate)
            .then(data => {
                const grandTotal = _.sumBy(data, (e: SavingsByCategory) => e.total)
                const pieChartData = data.map((saving: SavingsByCategory) => {
                    return {
                        name: saving.categoryName,
                        value: saving.total
                    }    
                })
                const savings = data.map((saving: SavingsByCategory) => {
                    return {
                        category: saving.categoryName,
                        amount: saving.total,
                        share: ((saving.total / grandTotal) * 100).toFixed(2) + " %"
                    }
                })
                const orderedSavings: any = _.orderBy(savings, ['amount'], ['desc'])
                
                setPieChartData(pieChartData)
                setSavings(orderedSavings)
                setLoaded(true)
            })
            .catch(e => setLoaded(true))
    }, [props.startDate, props.endDate])

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="savings-by-category-tabs"
                >
                    <Tab label="Chart" icon={<PieChartIcon />} />
                    <Tab label="List" icon={<ListIcon />} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTabIndex} index={0} id={'savings-by-category-chart'} ariaLabel={'Savings by category chart'}>
                <ChartHeader
                    title='Category-wise Savings'
                    csvData={savings}
                />
                <SimplePieChart loaded={loaded} data={pieChartData}/>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} id={'savings-by-category-list'} ariaLabel={'Savings by category list'}>
                <ChartHeader
                    title='Category-wise Savings'
                    csvData={savings}
                />
                <SavingsByCategoryList savingsByCategory={savings}/>
            </TabPanel>
        </div>
    );
}

export default SavingsByCategoryTab
