import React, { Component } from 'react';
import _ from "lodash";
import { RootState } from "../../store";
import {
    fetchAllIncomes,
    addIncome,
    editIncome,
    removeIncome
} from "../../store/incomes/actions";
import { fetchAllAccounts } from "../../store/accounts/actions";
import { fetchAllIncomeSources, addIncomeSource, editIncomeSource, removeIncomeSource } from "../../store/categories/actions";
import { connect, ConnectedProps } from "react-redux";
import { Income, AddOrEditIncomeRequestBody } from './Income';
import FormDialog from '../Dialog/FormDialog';
import AddOrEditIncomeForm from './AddOrEditIncomeForm';
import DeleteIncomeForm from './DeleteIncomeForm';
import IncomesTable from './IncomesTable';
import { Grid } from '@material-ui/core';
import { Category } from '../Categories/Category';
import { AddOrEditCategoryRequestBody } from '../../store/categories/types';
import AddOrEditCategoryForm from '../Categories/AddOrEditCategoryForm';
import DeleteCategoryForm from '../Categories/DeleteCategoryForm';
import CategoryList from '../Categories/CategoryList';
import DurationPicker from '../Duration/DurationPicker';
import { currentMonthStartDate, currentMonthEndDate } from '../Duration/DurationConfig';
import { convertDateToApiParamString, isWithInRange } from '../../utils/dateUtils';

const mapStateToProps = (state: RootState) => ({
    incomes: state.incomes,
    accounts: state.accounts,
    incomeSources: state.categories.incomeSources,
    defaultIncomeSource: state.settings.preferences.defaultIncomeCategory,
    defaultAccountId: state.settings.preferences.defaultIncomeAccount
})
const mapDispatchToProps = {
    fetchAllIncomes, addIncome,
    editIncome, removeIncome,
    fetchAllIncomeSources, addIncomeSource,
    editIncomeSource, removeIncomeSource,
    fetchAllAccounts
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type IncomesProps = ConnectedProps<typeof connector>
type IncomeFormType = 'add_income' | 'edit_income' | 'delete_income'
type IncomeSourceFormType = 'add_income_source' | 'edit_income_source' | 'delete_income_source'
type FormType = IncomeFormType | IncomeSourceFormType
type SelectedIncome = Income | null
type SelectedIncomeSource = Category | null
type FormStatus = {
    formTitle: string
    formType: FormType | null
    selectedIncome: SelectedIncome
    selectedIncomeSource: SelectedIncomeSource
}
type IncomesState = {
    formStatus: FormStatus
}

class Incomes extends Component<IncomesProps, IncomesState> {
    startDate: Date = currentMonthStartDate;
    endDate: Date = currentMonthEndDate;

    constructor(props: IncomesProps) {
        super(props)
        this.state = {
            formStatus: {
                formTitle: "",
                formType: null,
                selectedIncome: null,
                selectedIncomeSource: null
            }
        }
    }

    componentDidMount(): void {
        this.props.fetchAllIncomes({
            startDate: convertDateToApiParamString(currentMonthStartDate),
            endDate: convertDateToApiParamString(currentMonthEndDate)
        })
        this.props.fetchAllIncomeSources()
        this.props.fetchAllAccounts()
    }

    getDefaultIncome = (): Income => {
        return {
            id: '', name: '', date: new Date().toISOString(), amount: 0, incomeSourceId: this.props.defaultIncomeSource,
            accountId: this.props.defaultAccountId
        }
    }

    setIncomeFormStatus = (formTitle: string, formType: IncomeFormType | null, selectedIncome: SelectedIncome = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedIncome, selectedIncomeSource: null
            }
        })
    }

    setIncomeSourceFormStatus = (formTitle: string, formType: IncomeSourceFormType | null, selectedIncomeSource: SelectedIncomeSource = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedIncomeSource, selectedIncome: null
            }
        })
    }

    closeForm = () => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle: "",
                formType: null,
                selectedIncome: null,
                selectedIncomeSource: null
            }
        })
    }

    renderFormDialog = () => {
        if (this.state.formStatus.formType) {
            let formComponent = null

            switch (this.state.formStatus.formType) {
                case 'add_income': {
                    const submitForm = (formValues: AddOrEditIncomeRequestBody) => {
                        this.closeForm()
                        this.props.addIncome(formValues)
                    }
                    formComponent = <AddOrEditIncomeForm
                        accounts={this.props.accounts}
                        income={this.getDefaultIncome()}
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                        incomeSources={this.props.incomeSources}
                    />
                    break
                }
                case 'edit_income': {
                    if (this.state.formStatus.selectedIncome) {
                        const id = this.state.formStatus.selectedIncome.id
                        const submitForm = (formValues: AddOrEditIncomeRequestBody) => {
                            this.closeForm()
                            this.props.editIncome(id, formValues)
                        }
                        formComponent = <AddOrEditIncomeForm
                            accounts={this.props.accounts}
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            incomeSources={this.props.incomeSources}
                            income={this.state.formStatus.selectedIncome}
                        />
                    }
                    break
                }
                case 'delete_income': {
                    if (this.state.formStatus.selectedIncome) {
                        const id = this.state.formStatus.selectedIncome.id
                        const submitForm = (formValues: AddOrEditIncomeRequestBody) => {
                            this.closeForm()
                            this.props.removeIncome(id)
                        }
                        formComponent = <DeleteIncomeForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            income={this.state.formStatus.selectedIncome}
                        />
                    }
                    break
                }
                case 'add_income_source': {
                    const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                        this.closeForm()
                        this.props.addIncomeSource(formValues)
                    }
                    formComponent = <AddOrEditCategoryForm
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                    />
                    break
                }
                case 'edit_income_source': {
                    if (this.state.formStatus.selectedIncomeSource) {
                        const id = this.state.formStatus.selectedIncomeSource.id
                        const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                            this.closeForm()
                            this.props.editIncomeSource(id, formValues)
                        }
                        formComponent = <AddOrEditCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedIncomeSource}
                        />
                    }
                    break
                }
                case 'delete_income_source': {
                    if (this.state.formStatus.selectedIncomeSource) {
                        const id = this.state.formStatus.selectedIncomeSource.id
                        const submitForm = () => {
                            this.closeForm()
                            this.props.removeIncomeSource(id)
                        }
                        formComponent = <DeleteCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedIncomeSource}
                        />
                    }
                    break
                }
            }

            return (
                <FormDialog
                    title={this.state.formStatus.formTitle}
                    closeDialog={this.closeForm}
                    open
                >
                    {formComponent}
                </FormDialog>
            )
        }

        return null
    }

    onDurationSelect = (range: Date[]) => {
        this.startDate = range[0];
        this.endDate = range[1];
        
        this.props.fetchAllIncomes({
            startDate: convertDateToApiParamString(range[0]),
            endDate: convertDateToApiParamString(range[1])
        })
    }

    render() {
        const incomes = _.filter(this.props.incomes, e => isWithInRange(new Date(e.date), this.startDate, this.endDate))

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DurationPicker onSelect={this.onDurationSelect}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <IncomesTable
                            accounts={this.props.accounts}
                            incomes={incomes}
                            incomeSources={this.props.incomeSources}
                            addIncome={() => this.setIncomeFormStatus("Add Income", "add_income")}
                            editIncome={(income: Income) => this.setIncomeFormStatus("Edit Income", "edit_income", income)}
                            deleteIncome={(income: Income) => this.setIncomeFormStatus("Delete Income", "delete_income", income)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CategoryList
                            title={'Income Sources'}
                            categories={this.props.incomeSources}
                            addCategory={() => this.setIncomeSourceFormStatus('Add Income Source', 'add_income_source')}
                            editCategory={(c: Category) => this.setIncomeSourceFormStatus('Edit Income Source', 'edit_income_source', c)}
                            deleteCategory={(c: Category) => this.setIncomeSourceFormStatus('Delete Income Source', 'delete_income_source', c)}
                        />
                    </Grid>
                </Grid>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default connector(Incomes)