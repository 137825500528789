import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

type FormDialogProps = {
    children?: React.Component | JSX.Element | null | undefined
    title: string
    closeDialog: () => void
    open: boolean
}

export default function FormDialog(props: FormDialogProps) {
    return(
        <Dialog disableBackdropClick onClose={props.closeDialog} aria-labelledby={props.title} open={props.open}>
            <DialogTitle id={props.title}>{props.title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
        </Dialog>
    )
}