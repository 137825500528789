import React from 'react';
import { Button } from '@material-ui/core';

interface DeleteResourceFormProps {
    resource: any
    submitForm: (resource: any) => void
    cancel: () => void
}

const DeleteResourceForm = (props: DeleteResourceFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.resource)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.cancel} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.resource)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteResourceForm;