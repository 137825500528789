import React, { Component, ReactNode } from 'react';
import moment from "moment";
import CalendarIcon from "@material-ui/icons/DateRange";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {
    currentMonthStartDate,
    currentMonthEndDate,
    lastWeekStartDate,
    todaysDate,
    last3MonthsStartDate,
    last6MonthsStartDate,
    last12MonthsStartDate,
    currentYearStartDate,
    currentYearEndDate
} from './DurationConfig';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

type DurationPickerProps = {
    onSelect: (range: Date[]) => void
    fromDate?: Date
    toDate?: Date
}

type DurationPickerState = {
    customFromDate: Date
    customToDate: Date
    selectedDurationOption: string
    openDateRangePicker: boolean
}

class DurationPricker extends Component<DurationPickerProps, DurationPickerState> {
    constructor(props: DurationPickerProps) {
        super(props)
        this.state = {
            customFromDate: this.props.fromDate || new Date(currentMonthStartDate),
            customToDate: this.props.toDate || new Date(currentMonthEndDate),
            selectedDurationOption: (this.props.fromDate && this.props.toDate) ? "custom" : "this-month",
            openDateRangePicker: false
        }
    }

    onDateRangePickerRange = (values: Date[]) => {
        this.setState({
            customFromDate: new Date(moment(values[0]).startOf("day").format()),
            customToDate: new Date(moment(values[1]).endOf("day").format())
        })
        this.props.onSelect(values)
    }

    onDurationOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            selectedDurationOption: (event.target.value as string)
        })

        if (event.target.value === "custom") {
            this.setState({
                openDateRangePicker: true
            })
            this.props.onSelect([
                this.state.customFromDate,
                this.state.customToDate
            ])
            return
        }

        if (event.target.value === "this-month") {
            this.props.onSelect([
                currentMonthStartDate, currentMonthEndDate
            ])
        } else if (event.target.value === "last-week") {
            this.props.onSelect([
                lastWeekStartDate, todaysDate
            ])
        } else if (event.target.value === "this-year") {
            this.props.onSelect([
                currentYearStartDate, currentYearEndDate
            ])
        } else if (event.target.value === "last-3-months") {
            this.props.onSelect([
                last3MonthsStartDate, currentMonthEndDate
            ])
        } else if (event.target.value === "last-6-months") {
            this.props.onSelect([
                last6MonthsStartDate, currentMonthEndDate
            ])
        } else if (event.target.value === "last-12-months") {
            this.props.onSelect([
                last12MonthsStartDate, currentMonthEndDate
            ])
        }

        this.setState({
            openDateRangePicker: false
        })
    }

    render(): ReactNode {
        return (
            <React.Fragment>
                <FormControl>
                    <Select
                        labelId="date-range-label"
                        id="date-range"
                        value={this.state.selectedDurationOption}
                        onChange={this.onDurationOptionChange}
                        label="Choose Date Range"
                    >
                        <MenuItem value="this-month">This Month</MenuItem>
                        <MenuItem value="this-year">This Year</MenuItem>
                        <MenuItem value="last-week">Last Week</MenuItem>
                        <MenuItem value="last-3-months">Last 3 Months</MenuItem>
                        <MenuItem value="last-6-months">Last 6 Months</MenuItem>
                        <MenuItem value="last-12-months">Last 12 Months</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </FormControl>
                <span>
                {this.state.openDateRangePicker && <DateRangePicker
                    onChange={this.onDateRangePickerRange}
                    value={[this.state.customFromDate, this.state.customToDate]}
                    format={"dd MMM yyyy"}
                    name={'duration'}
                    clearIcon={null}
                    isOpen
                    rangeDivider={<span style={{ marginRight: '5px', marginLeft: '5px' }}>-</span>}
                    calendarIcon={<CalendarIcon color={"inherit"} />}
                />}
                </span>
            </React.Fragment>
        )
    }
}

export default DurationPricker