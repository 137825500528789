import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import _ from "lodash";
import { Income } from "./Income";
import { Category } from "../Categories/Category";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import Amount from "../App/Currency/Amount";
import moment from "moment";
import { Account } from "../Accounts/Account";

type IncomesTableProps = {
    incomes: Income[]
    incomeSources: Category[]
    accounts: Account[]
    addIncome: () => void
    editIncome: (income: Income) => void
    deleteIncome: (income: Income) => void
}

type TableData = {
    Id: string
    Name: string
    Amount: number
    Date: string
    "Income Source": string
    Account: string
}

const IncomesTable = (props: IncomesTableProps) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [filterList, setFilterList] = useState([
        [], [], [], [], [], [], []
    ])
    const [searchText, setSearchText] = useState("")
    const columnConfig: MUIDataTableColumnDef[] = [
        {
            name: "Id",
            options: {
                display: 'excluded',
                download: false,
                filter: false,
                sort: false,
                searchable: false
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id: string = tableMeta.rowData[0]
                    const income: Income | undefined = _.find(props.incomes, { id })
                    if (income) {
                        return (
                            <React.Fragment>
                                <IconButton size={"small"} onClick={() => props.editIncome(income)}>
                                    <EditIcon fontSize={'small'} />
                                </IconButton>
                                <IconButton size={"small"} onClick={() => props.deleteIncome(income)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </React.Fragment>
                        )
                    }

                    return null
                }
            }
        },
        {
            name: "Name",
            options: {
                filter: false
            }
        },
        {
            name: "Amount",
            options: {
                filter: false,
                customBodyRender: (value: any) => <Amount value={value} />
            }
        },
        {
            name: "Date",
            options: {
                sortDirection: "desc",
                filter: false,
                customBodyRender: value => <Moment format="DD MMM yyyy">{value}</Moment>
            }
        },
        {
            name: "Income Source",
            options: {
                filterList: filterList[5].length ? filterList[5] : undefined
            }
        },
        {
            name: "Account",
            options: {
                filterList: filterList[6].length ? filterList[6] : undefined
            }
        }
    ]

    const tableOptions: MUIDataTableOptions = {
        selectableRows: "none",
        elevation: 4,
        print: false,
        searchText: searchText,
        responsive: "scrollFullHeight",
        downloadOptions: {
            filename: 'incomes.csv'
        },
        customToolbar: () => {
            return (
                <Tooltip title={"Add Income"}>
                    <IconButton onClick={props.addIncome}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            data.forEach((row: any) => {
                row.data[4] = moment(row.data[4]).format("DD MMM YYYY")
            })
            return `${buildHead(columns)}${buildBody(data)}`.trim();
        },
        onFilterChange: (columnChanged: string, filterList: any) => {
            setFilterList(filterList)
        },
        onSearchChange: (text: string) => {
            setSearchText(text)
        },
        onTableChange: (_action: string, tableState: MUIDataTableState) => {
            let total = 0;
            tableState.displayData.forEach((row: any) => {
                const idx = row.dataIndex;
                total += tableState.data[idx].data[3]
            })
            setTotalAmount(total);
        }
    }

    const prepareData = (incomes: Income[], incomeSources: Category[], accounts: Account[]) => {
        const tableData: TableData[] = [];

        incomes.forEach(income => {
            const incomeSource = _.find(incomeSources, (e) => e.id === income.incomeSourceId);
            const account = _.find(accounts, (e) => e.id === income.accountId)
            tableData.push({
                "Id": income.id,
                "Name": income.name,
                "Amount": income.amount,
                "Date": income.date,
                "Income Source": incomeSource ? incomeSource.name : "NA",
                "Account": account ? account.name : "NA"
            })
        })

        return tableData;
    }

    useEffect(() => {
        setTotalAmount(_.sumBy(props.incomes, (e: Income) => e.amount))
    }, [props.incomes])

    return (
        <MUIDataTable
            title={<h3>Incomes: <Amount value={totalAmount} /></h3>}
            data={prepareData(props.incomes, props.incomeSources, props.accounts)}
            columns={columnConfig}
            options={tableOptions}
        />
    )
}

export default IncomesTable