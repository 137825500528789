import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import DownloadCSVButton from "../CSV/DownloadCSV";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        paddingBottom: theme.spacing(1)
    },
    download: {
        textAlign: 'right'
    }
}))

interface ChartHeaderProps {
    title: string
    csvData: any
    csvFileName?: string
}

const ChartHeader = (props: ChartHeaderProps) => {
    const classes = useStyles()

    return (
        <div>
            <p className={classes.title}>{props.title}
                <span className={classes.download}>
                    <DownloadCSVButton
                        data={props.csvData}
                        fileName={props.csvFileName ? props.csvFileName : `${props.title}.csv`}
                    />
                </span>
            </p>
        </div>
    )
}

export default ChartHeader