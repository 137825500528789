import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {Category} from "./Category";

type CategoryListItemProps = {
    item: Category
    editCategory: (category: Category) => void
    deleteCategory: (category: Category) => void
}

const CategoryListItem = (props: CategoryListItemProps) => {
    return(
        <div>
            <IconButton size={"small"} onClick={() => props.editCategory(props.item)}>
                <EditIcon fontSize={'small'}/>
            </IconButton>
            <IconButton size={"small"} onClick={() => props.deleteCategory(props.item)}>
                <DeleteIcon fontSize={'small'}/>
            </IconButton>
            {props.item.name}
        </div>
    )
}

export default CategoryListItem