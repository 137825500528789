import React from 'react';
import { Income } from './Income';
import { Button } from '@material-ui/core';

type DeleteIncomeFormProps = {
    income: Income
    submitForm: (formValues: Income) => void
    closeForm: () => void
}

const DeleteIncomeForm = (props: DeleteIncomeFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.income)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.income)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteIncomeForm;