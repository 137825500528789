import { AppThunk } from "../index";
import { batch } from "react-redux";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import {
    getAllDeductionsCategory,
    getAllExpensesCategory, getAllIncomeSources,
    getAllSavingsCategory,
    createExpensesCategory,
    createSavingsCategory,
    createIncomeSource,
    createDeductionsCategory,
    updateExpensesCategory,
    updateSavingsCategory,
    updateIncomeSource,
    updateDeductionsCategory,
    deleteExpensesCategory,
    deleteDeductionsCategory,
    deleteIncomeSource,
    deleteSavingsCategory
} from "../../services/categoriesService";
import {
    FETCH_ALL_DEDUCTIONS_CATEGORIES,
    FETCH_ALL_EXPENSES_CATEGORIES,
    FETCH_ALL_INCOME_SOURCES,
    FETCH_ALL_SAVINGS_CATEGORIES,
    AddOrEditCategoryRequestBody,
    ADD_EXPENSES_CATEGORY,
    ADD_SAVINGS_CATEGORY,
    ADD_INCOME_SOURCE,
    ADD_DEDUCTIONS_CATEGORY,
    EDIT_EXPENSES_CATEGORY,
    EDIT_SAVINGS_CATEGORY,
    EDIT_INCOME_SOURCE,
    EDIT_DEDUCTIONS_CATEGORY,
    REMOVE_EXPENSES_CATEGORY,
    REMOVE_DEDUCTIONS_CATEGORY,
    REMOVE_INCOME_SOURCE,
    REMOVE_SAVINGS_CATEGORY
} from "./types";

export const fetchAllExpensesCategories = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllExpensesCategory()

        batch(() => {
            dispatch({
                type: FETCH_ALL_EXPENSES_CATEGORIES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to fetch expenses categories", true))
        })
    }
}

export const fetchAllSavingsCategories = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllSavingsCategory()

        batch(() => {
            dispatch({
                type: FETCH_ALL_SAVINGS_CATEGORIES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to fetch savings categories", true))
        })
    }
}

export const fetchAllDeductionsCategories = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllDeductionsCategory()

        batch(() => {
            dispatch({
                type: FETCH_ALL_DEDUCTIONS_CATEGORIES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        dispatch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to fetch deductions categories", true))
        })
    }
}

export const fetchAllIncomeSources = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllIncomeSources()

        batch(() => {
            dispatch({
                type: FETCH_ALL_INCOME_SOURCES,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to fetch income sources", true))
        })
    }
}

export const addExpensesCategory = (requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createExpensesCategory(requestBody)

        batch(() => {
            dispatch({
                type: ADD_EXPENSES_CATEGORY,
                payload: response.data.data
            })
            dispatch(fetchAllExpensesCategories())
            dispatch(showNotification("Category added successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addSavingsCategory = (requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createSavingsCategory(requestBody)

        batch(() => {
            dispatch({
                type: ADD_SAVINGS_CATEGORY,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category added successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addIncomeSource = (requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createIncomeSource(requestBody)

        batch(() => {
            dispatch({
                type: ADD_INCOME_SOURCE,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category added successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addDeductionsCategory = (requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createDeductionsCategory(requestBody)

        batch(() => {
            dispatch({
                type: ADD_DEDUCTIONS_CATEGORY,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category added successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editExpensesCategory = (id: string, requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateExpensesCategory(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_EXPENSES_CATEGORY,
                payload: response.data.data
            })
            dispatch(fetchAllExpensesCategories())
            dispatch(showNotification("Category updated successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editSavingsCategory = (id: string, requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateSavingsCategory(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_SAVINGS_CATEGORY,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category updated successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editIncomeSource = (id: string, requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateIncomeSource(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_INCOME_SOURCE,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category updated successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editDeductionsCategory = (id: string, requestBody: AddOrEditCategoryRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateDeductionsCategory(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_DEDUCTIONS_CATEGORY,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category updated successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeExpensesCategory = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteExpensesCategory(id)

        batch(() => {
            dispatch({
                type: REMOVE_EXPENSES_CATEGORY,
                payload: { id }
            })
            dispatch(fetchAllExpensesCategories())
            dispatch(showNotification("Category removed successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeSavingsCategory = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteSavingsCategory(id)

        batch(() => {
            dispatch({
                type: REMOVE_SAVINGS_CATEGORY,
                payload: { id }
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category removed successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeIncomeSource = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteIncomeSource(id)

        batch(() => {
            dispatch({
                type: REMOVE_INCOME_SOURCE,
                payload: { id }
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category removed successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeDeductionsCategory = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteDeductionsCategory(id)

        batch(() => {
            dispatch({
                type: REMOVE_DEDUCTIONS_CATEGORY,
                payload: { id }
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Category removed successfully"))
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}