import React, {memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AddIcon from "@material-ui/icons/Add";
import CardHeader from "@material-ui/core/CardHeader";
import CategoryListItem from "./CategoryListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Category } from "./Category";
import { Tooltip, IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    categoryItem: {
        margin: theme.spacing(0)
    }
}))

type CategoryListProps = {
    title: string
    categories: Category[]
    addCategory: () => void
    editCategory: (category: Category) => void
    deleteCategory: (category: Category) => void
}

const CategoryList = (props: CategoryListProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState("");

    const handleExpansion = (panelId: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panelId : "");
    };

    const renderExpansionPanelSummary = (category: Category, showExpandMore: boolean) => {
        return (
            <ExpansionPanelSummary
                className={classes.categoryItem}
                expandIcon={showExpandMore ? <ExpandMoreIcon /> : null}
                aria-controls={category.name}
                id={category.id}
            >
                <CategoryListItem
                    item={category}
                    editCategory={props.editCategory}
                    deleteCategory={props.deleteCategory}
                />
            </ExpansionPanelSummary>
        )
    }

    const renderExpansionPanelDetails = (categories: Category[], showExpandMore: boolean) => {
        if (!showExpandMore) {
            return null
        }

        return (
            <ExpansionPanelDetails>
                {categories.map((category: Category) => {
                    return (
                        <CategoryListItem
                            key={category.id}
                            item={category}
                            editCategory={props.editCategory}
                            deleteCategory={props.deleteCategory}
                        />
                    )
                })}
            </ExpansionPanelDetails>
        )
    }

    const renderCategoryItemList = () => {
        return props.categories.map((category: Category) => {
            const showExpandMore: boolean = Boolean(category.subCategories && category.subCategories.length);
            const expansionPanelSummary = renderExpansionPanelSummary(category, showExpandMore)
            const expansionPanelDetails = renderExpansionPanelDetails(category.subCategories, showExpandMore)
            const expandPanel: boolean = Boolean((expanded === category.id) && (showExpandMore))

            return (
                <ExpansionPanel
                    elevation={0}
                    key={category.id}
                    expanded={expandPanel}
                    onChange={handleExpansion(category.id)}
                >
                    {expansionPanelSummary}
                    {expansionPanelDetails}
                </ExpansionPanel>
            )
        })
    }

    const renderAddNewCategoryActionBtn = () => {
        return (
            <Tooltip title={`Add ${props.title}`}>
                <IconButton onClick={props.addCategory}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        )
    }


    return (
        <Card elevation={4}>
            <CardHeader title={props.title} action={renderAddNewCategoryActionBtn()} />
            <CardContent>{renderCategoryItemList()}</CardContent>
        </Card>
    )
}

export default memo(CategoryList)