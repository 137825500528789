import apiClient from './apiClient'
import { AddOrEditDeductionRequestBody } from '../components/Deductions/Deduction'

export const getAllDeductions = (params?: any) => apiClient.get("/deduction", {params})
export const createDeduction = (requestBody: AddOrEditDeductionRequestBody) => (
    apiClient.post("/deduction", requestBody)
)
export const updateDeduction = (id: string, requestBody: AddOrEditDeductionRequestBody) => (
    apiClient.put(`/deduction/${id}`, requestBody)
)
export const deleteDeduction = (id: string) => apiClient.delete(`/deduction/${id}`)