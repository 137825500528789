import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, PieLabelRenderProps, PieProps, Sector, Legend } from "recharts";
import { defaultWidth, defaultHeight, defaultMargin } from "./ChartsConfig";
import CustomTooltip from "./CustomTooltip";
import { Box, CircularProgress } from "@material-ui/core";

const colors = [
    '#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e',
    '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262',
    '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d',
    '#668d1c', '#bea413', '#0c5922', '#743411'
];

interface SimplePieChartProps {
    data: any,
    loaded?: boolean
}

const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, 
        fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    
    return (
        <g>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
            <text dy={18} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{`${payload.value.toLocaleString()} (${(percent * 100).toFixed(2)}%)`}</text>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        </g>
    );
};

const SimplePieChart = (props: SimplePieChartProps) => {
    const { theme } = useContext(ThemeContext);
    const fill = theme === "dark" ? "#fff" : "#000";

    if (!props.loaded) {
        return <Box textAlign="center"><CircularProgress /></Box>
    }

    if (!props.data.length) {
        return <h3 style={{ textAlign: "center" }}>No Data</h3>
    }

    return (
        <ResponsiveContainer width={defaultWidth} height={defaultHeight}>
            <PieChart margin={defaultMargin} data={props.data}>
                <Tooltip content={<CustomTooltip />} />
                <Legend/>
                <Pie dataKey="value" data={props.data} fill={fill} label={renderCustomizedLabel}>
                    {
                        props.data.map((_entry: any, index: number) => <Cell fill={colors[index % colors.length]} />)
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

export default SimplePieChart