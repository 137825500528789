import React from 'react';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {Avatar, Button} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import {logoutFromServer} from "../../store/user/actions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const mapStateToProps = (state: RootState) =>({
    userProfile: state.user.profile || {}
})

const mapDispatchToProps = {
    logoutFromServer
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type ProfileProps =  ConnectedProps<typeof connector>

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

const Profile = (props: ProfileProps) => {
    const classes = useStyles()

    return(
        <Grid container>
            <Grid item xs={12} md={4}>
                <Card elevation={4}>
                    <CardContent>
                        <Avatar alt="user avatar" src={props.userProfile.imageUrl} className={classes.large} />
                        <h2>{props.userProfile.name}</h2>
                        <h4>{props.userProfile.email}</h4>
                        <Button onClick={() => props.logoutFromServer()} variant="contained" color="secondary">
                            Log out
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default connector(Profile)