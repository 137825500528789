import React, { memo, useState, useEffect } from "react";
import Moment from "react-moment";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import _ from "lodash";
import { Expense } from "./Expense";
import { Category } from "../Categories/Category";
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Amount from "../App/Currency/Amount";
import moment from "moment";
import { Account } from "../Accounts/Account";

type ExpensesTableProps = {
    expenses: Expense[]
    expensesCategories: Category[]
    accounts: Account[]
    addExpense: () => void
    editExpense: (expense: Expense) => void
    deleteExpense: (expense: Expense) => void
}

type TableData = {
    Id: string
    Name: string
    Amount: number
    Date: string
    Category: string
    "Sub Category": string
    "Payment Type": string
    Account: string
}

const ExpensesTable = (props: ExpensesTableProps) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [filterList, setFilterList] = useState([
        [], [], [], [], [], [], [], [], []
    ])
    const [searchText, setSearchText] = useState("")
    const columnConfig: MUIDataTableColumnDef[] = [
        {
            name: "Id",
            options: {
                display: 'excluded',
                download: false,
                filter: false,
                sort: false,
                searchable: false
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                download: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id: string = tableMeta.rowData[0]
                    const expense: Expense | undefined = _.find(props.expenses, { id })
                    if (expense) {
                        return (
                            <React.Fragment>
                                <IconButton size={"small"} onClick={() => props.editExpense(expense)}>
                                    <EditIcon fontSize={'small'} />
                                </IconButton>
                                <IconButton size={"small"} onClick={() => props.deleteExpense(expense)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </React.Fragment>
                        )
                    }

                    return null
                }
            }
        },
        {
            name: "Name",
            options: {
                filter: false
            }
        },
        {
            name: "Amount",
            options: {
                filter: false,
                customBodyRender: (value: any) => <Amount value={value} />
            }
        },
        {
            name: "Date",
            options: {
                sortDirection: "desc",
                filter: false,
                customBodyRender: value => <Moment format="DD MMM yyyy">{value}</Moment>
            }
        },
        {
            name: "Category",
            options: {
                filterList: filterList[5].length ? filterList[5] : undefined
            }
        },
        {
            name: "Sub Category",
            options: {
                filterList: filterList[6].length ? filterList[6] : undefined
            }
        },
        {
            name: "Payment Type",
            options: {
                filterList: filterList[7].length ? filterList[7] : undefined
            }
        },
        {
            name: "Account",
            options: {
                filterList: filterList[8].length ? filterList[8] : undefined
            }
        }
    ]

    const tableOptions: MUIDataTableOptions = {
        selectableRows: "none",
        elevation: 4,
        print: false,
        responsive: "scrollFullHeight",
        searchText: searchText,
        downloadOptions: {
            filename: 'expenses.csv'
        },
        customToolbar: () => {
            return (
                <Tooltip title={"Add Expense"}>
                    <IconButton onClick={props.addExpense}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            data.forEach((row: any) => {
                row.data[4] = moment(row.data[4]).format("DD MMM YYYY")
            })
            return `${buildHead(columns)}${buildBody(data)}`.trim();
        },
        onFilterChange: (_columnChanged: string, filterList: any) => {
            setFilterList(filterList)
        },
        onSearchChange: (text: string) => {
            setSearchText(text)
        },
        onTableChange: (_action: string, tableState: MUIDataTableState) => {
            let total = 0;
            tableState.displayData.forEach((row: any) => {
                const idx = row.dataIndex;
                total += tableState.data[idx].data[3]
            })
            setTotalAmount(total);
        }
    }

    const prepareData = (expenses: Expense[], expensesCategories: Category[], accounts: Account[]) => {
        const tableData: TableData[] = [];

        expenses.forEach(expense => {
            const category = _.find(expensesCategories, (e) => e.id === expense.categoryId);
            const account = _.find(accounts, (e) => e.id === expense.accountId)
            let subCategory = null;
            if (category && expense.subCategoryId) {
                subCategory = _.find(category.subCategories, (e) => e.id === expense.subCategoryId)
            }
            tableData.push({
                "Id": expense.id,
                "Name": expense.name,
                "Amount": expense.amount,
                "Date": expense.date,
                "Category": category ? category.name : "NA",
                "Sub Category": subCategory ? subCategory.name : "NA",
                "Payment Type": expense.paymentType,
                "Account": account ? account.name : "NA"
            })
        })

        return tableData;
    }

    useEffect(() => {
        setTotalAmount(_.sumBy(props.expenses, (e: Expense) => e.amount))
    }, [props.expenses])

    return (
        <MUIDataTable
            title={<h3>Expenses: <Amount value={totalAmount} /></h3>}
            data={prepareData(props.expenses, props.expensesCategories, props.accounts)}
            columns={columnConfig}
            options={tableOptions}
        />
    )
}

export default memo(ExpensesTable)