import React from 'react';
import { Saving } from './Saving';
import { Button } from '@material-ui/core';

type DeleteSavingsFormProps = {
    saving: Saving
    submitForm: (formValues: Saving) => void
    closeForm: () => void
}

const DeleteSavingsForm = (props: DeleteSavingsFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.saving)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.saving)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteSavingsForm;