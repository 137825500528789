import { batch } from "react-redux";
import { AppThunk } from "..";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import { FETCH_ALL_ACCOUNTS, REMOVE_ACCOUNT, ADD_ACCOUNT, EDIT_ACCOUNT } from "./types";
import { AddOrEditAccountRequestBody } from "../../components/Accounts/Account";
import { createAccount, updateAccount, deleteAccount, getAllAccounts } from "../../services/accountsService";

export const fetchAllAccounts = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllAccounts()

        batch(() => {
            dispatch({
                type: FETCH_ALL_ACCOUNTS,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addAccount = (requestBody: AddOrEditAccountRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createAccount(requestBody)

        batch(() => {
            dispatch({
                type: ADD_ACCOUNT,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Account added successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editAccount = (id: string, requestBody: AddOrEditAccountRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateAccount(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_ACCOUNT,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Account updated successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeAccount = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteAccount(id)

        batch(() => {
            dispatch({
                type: REMOVE_ACCOUNT,
                payload: {id}
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Account removed successfully"))
        })
    } catch(e) {
        dispatch(hidePageLoader())
        dispatch(showApiError(e))
    }
}