import { AppThunk } from "..";
import { batch } from "react-redux";
import { showApiError, hidePageLoader, showPageLoader, showNotification } from "../app/actions";
import { getPreferences, updatePreferences } from "../../services/settingsService";
import { FETCH_PREFERENCES, EDIT_PREFERENCES } from "./types";
import { Preferences } from "../../components/Settings/Preferences/Preferences";
import { setUserPreferences } from "../../utils/userUtils";

export const fetchPreferences = (): AppThunk => async dispatch => {
    try {
        showPageLoader()

        const response = await getPreferences()

        batch(() => {
            dispatch({
                type: FETCH_PREFERENCES,
                payload: response.data.data
            })
            hidePageLoader()
        })
        setUserPreferences(response.data.data)
    } catch(e) {
        batch(() => {
            hidePageLoader()
            showApiError(e)
        })
    }
}

export const editPreferences = (preferences: Preferences): AppThunk => async dispatch => {
    try {
        showPageLoader()

        const response = await updatePreferences(preferences)

        batch(() => {
            dispatch({
                type: EDIT_PREFERENCES,
                payload: response.data.data
            })
            dispatch(showNotification("Preferences updated successfully"))
            dispatch(hidePageLoader())
        })
        setUserPreferences(response.data.data)
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}