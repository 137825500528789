import React, { useContext } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import { defaultWidth, defaultHeight, defaultMargin, defaultXPadding } from "./ChartsConfig";
import ThemeContext from "../../context/ThemeContext";
import CustomTooltip from "./CustomTooltip";
import { Box, CircularProgress } from "@material-ui/core";

interface LineConfig {
    key: string
    fillColor: string
}

interface SimpleLineChartProps {
    data: any
    xAxisDataKey: string
    lineConfig: LineConfig[],
    showLegend?: boolean,
    loaded?: boolean
}

const SimpleLineChart = (props: SimpleLineChartProps) => {
    const { theme } = useContext(ThemeContext);
    const strokeColor = theme === "dark" ? "#fff" : "#000";
    
    const renderXAxis = () => <XAxis stroke={strokeColor} dataKey={props.xAxisDataKey} padding={defaultXPadding}/>
    const renderYAxis = () => <YAxis stroke={strokeColor} tickFormatter={(v) => v.toLocaleString()}/>
    const renderTooltip = () => <Tooltip cursor={false} content={<CustomTooltip/>}/>
    const renderLegend = () => props.showLegend ? <Legend/> : null
    const renderLines = () => (
        props.lineConfig.map((config: LineConfig) => (
            <Line
                key={config.key} dataKey={config.key} fill={config.fillColor}
            />
        ))
    )

    if (!props.loaded) {
        return(
            <Box textAlign="center"><CircularProgress/></Box>
        )
    }
    
    if (!props.data.length) {
        return <h3 style={{textAlign: "center"}}>No Data</h3>
    }
    
    return (
        <ResponsiveContainer width={defaultWidth} height={defaultHeight}>
            <LineChart margin={defaultMargin} data={props.data}>
                <CartesianGrid strokeDasharray="0 3" />
                {renderXAxis()}
                {renderYAxis()}
                {renderTooltip()}
                {renderLegend()}
                {renderLines()}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default SimpleLineChart