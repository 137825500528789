import React from 'react';
import GoogleLoginButton from "./GoogleLoginButton";
import {Typography} from "@material-ui/core";
import {loginInWithGoogle} from "../../store/user/actions";
import {connect, ConnectedProps} from "react-redux";

declare const window: any;

const mapDispatchToProps = {
    loginInWithGoogle
}

const connector = connect(null, mapDispatchToProps)

type LoginProps =  ConnectedProps<typeof connector>

const Login = (props: LoginProps) => {
    const onLoginSuccess = (response: any) => {
        props.loginInWithGoogle(response.getAuthResponse().id_token)
        window.gapi.auth2.getAuthInstance().disconnect()
    }

    const onLoginFailure = (error: any) => {
        console.log(error)
    }

    return(
        <div className={'Centered-container'}>
            <Typography variant={'h5'}>Expenses</Typography>
            <GoogleLoginButton
                onLoginFailure={onLoginFailure}
                onLoginSuccess={onLoginSuccess}
            />
        </div>
    )
}

export default connector(Login)