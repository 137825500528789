import React, {Component} from 'react';

declare const window: any;

type GoogleLoginButtonProps = {
    onLoginSuccess: (userData: object) => void
    onLoginFailure: (error: object) => void
}

const googleLoginBtnId: string = 'google-login-btn';

class GoogleLoginButton extends Component<GoogleLoginButtonProps> {

    componentDidMount() {
        if (window.gapi) {
            this.renderGoogleLoginButton()
        } else {
            window.addEventListener("load", this.renderGoogleLoginButton)
        }
    }

    renderGoogleLoginButton = () => {
        window.gapi.signin2.render(googleLoginBtnId, {
            'scope': 'profile email',
            'width': 240,
            'height': 50,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': this.props.onLoginSuccess,
            'onfailure': this.props.onLoginFailure
        })
    }

    render() {
        return <div id={googleLoginBtnId}/>
    }
}

export default GoogleLoginButton