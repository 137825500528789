import React from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Amount from "../../App/Currency/Amount";

interface CategoryItem {
    category: string
    amount: number
    share: string
}

interface SavingsByCategoryListProps {
    savingsByCategory: CategoryItem[]
}

const useStyles = makeStyles((theme: Theme) => ({
    categoryRow: {
        padding: theme.spacing(0.5)
    },
    categoryRowItem: {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0)
    }
}))

const CategoryItem = (props: CategoryItem) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Grid item xs={4}>
                <p className={classes.categoryRowItem}>{props.category}</p>
            </Grid>
            <Grid style={{textAlign: "right"}} item xs={4}>
                <p className={classes.categoryRowItem}><Amount value={props.amount}/></p>
            </Grid>
            <Grid style={{textAlign: "right"}} item xs={4}>
                <p className={classes.categoryRowItem}>{props.share}</p>
            </Grid>
        </React.Fragment>
    )
}

const SavingsByCategoryList = (props: SavingsByCategoryListProps) => {

    const renderList = () => {
        if (!props.savingsByCategory.length) {
            return <h3 style={{textAlign: "center"}}>No Data</h3>
        }
        
        return props.savingsByCategory.map((category: CategoryItem) => {
            return <CategoryItem key={category.category} category={category.category} amount={category.amount} share={category.share} />
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                {renderList()}
            </Grid>
        </React.Fragment>
    )
}

export default SavingsByCategoryList