import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TooltipProps } from "recharts";
import Amount from "../App/Currency/Amount";
import { Grid, Box, CardContent, Card } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "125%",
        backgroundColor: theme.palette.background.paper,
    },
    label: {
        textAlign: "center"
    },
    payload: {

    },
    amount: {
        textAlign: "right"
    }
}))

const CustomTooltip = (props: TooltipProps) => {
    const { active } = props
    const classes = useStyles()

    const renderPayload = () => {
        const { payload } = props
        if (payload) {
            return payload.map(p => (
                <p className={classes.payload} key={p.name}>
                    <Grid container>
                        <Grid item xs={6}>
                            {p.name}
                        </Grid>
                        <Grid item xs={6} className={classes.amount}>
                            <Amount value={p.value} />
                        </Grid>
                    </Grid>
                </p>
            ))
        }

        return null
    }

    if (active) {
        const { label } = props
        return (
            <Card elevation={4} className={classes.root}>
                <CardContent>
                    <p className={classes.label}>{label}</p>
                    {renderPayload()}
                </CardContent>
            </Card>
        )
    }

    return null
}

export default CustomTooltip;