import React from 'react';
import { Formik, Form, Field, FormikErrors, FormikValues } from 'formik';
import { Account, AddOrEditAccountRequestBody } from "./Account";
import { TextField } from 'formik-material-ui';
import { Button, FormControl } from '@material-ui/core';

interface AddOrEditAccountFormValues {
    name: string
    balance: number | ''
}

interface AddOrEditAccountFormProps {
    account?: Account | null
    submitForm: (formValues: AddOrEditAccountRequestBody) => void
    closeForm: () => void
}

const AddOrEditAccountForm = (props: AddOrEditAccountFormProps) => {
    const initialValues: AddOrEditAccountFormValues = {
        name: props.account ? props.account.name : '',
        balance: (props.account && props.account.balance) ? props.account.balance : ''
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={values => {
                const errors: FormikErrors<FormikValues> = {}
                if (!values.name || (!values.name.trim())) {
                    errors.name = "Name is required"
                }

                return errors
            }}
            onSubmit={(values) => {
                const formValues = { ...values }
                if (formValues.balance === "") {
                    formValues.balance = 0
                }

                props.submitForm(formValues)
            }}
        >
            {({ isValid, dirty, submitForm }) => (
                <Form>
                    <FormControl fullWidth>
                        <Field
                            autoFocus
                            className={'Form-input'}
                            component={TextField}
                            name="name"
                            label="Name"
                            required
                            inputProps={{
                                autocomplete: "off"
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Field
                            className={'Form-input'}
                            component={TextField}
                            name="balance"
                            label="Balance"
                            type="number"
                            inputProps={{
                                autocomplete: "off"
                            }}
                        />
                    </FormControl>
                    <div className="Form-actions">
                        <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="secondary">
                            Cancel
                        </Button>
                        <Button disabled={!dirty || !isValid} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default AddOrEditAccountForm