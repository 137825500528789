import apiClient from "./apiClient";

export const getDailyExpenditure = (params?: any) => apiClient.get("/analytics/weekly", {params})
export const getMonthlyExpenseTrend = (params?: any) => apiClient.get("/analytics/monthlyExpenseTrend", {params})
export const getMonthlyOverview = (params?: any) => apiClient.get("/analytics/monthly", {params})
export const getExpenditureByCategory = (params?: any) => apiClient.get("/analytics/category", {params})
export const getAllTimeStats = () => apiClient.get("/analytics/allTimeStats")
export const getIncomesByCategory = (params: any) => apiClient.get("/analytics/incomes", {params})
export const getDeductionsByCategory = (params: any) => apiClient.get("/analytics/deductions", {params})
export const getSavingsByCategory = (params: any) => apiClient.get("/analytics/savings", {params})
export const getPaymentMethodsByCategory = (params: any) => apiClient.get("/analytics/paymentMethods", {params})