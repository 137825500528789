import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import _ from "lodash";
import { Saving } from "./Saving";
import { Category } from "../Categories/Category";
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Amount from "../App/Currency/Amount";
import moment from "moment";
import { Account } from "../Accounts/Account";

type SavingsTableProps = {
    savings: Saving[]
    savingsCategories: Category[]
    accounts: Account[]
    addSaving: () => void
    editSaving: (saving: Saving) => void
    deleteSaving: (saving: Saving) => void
}

type TableData = {
    Id: string
    Name: string
    Amount: number
    Date: string
    "Category": string
    Account: string
}

const SavingsTable = (props: SavingsTableProps) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [filterList, setFilterList] = useState([
        [], [], [], [], [], [], []
    ])
    const [searchText, setSearchText] = useState("")
    const columnConfig: MUIDataTableColumnDef[] = [
        {
            name: "Id",
            options: {
                display: 'excluded',
                download: false,
                filter: false,
                sort: false,
                searchable: false
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id: string = tableMeta.rowData[0]
                    const saving: Saving | undefined = _.find(props.savings, { id })
                    if (saving) {
                        return (
                            <React.Fragment>
                                <IconButton size={"small"} onClick={() => props.editSaving(saving)}>
                                    <EditIcon fontSize={'small'} />
                                </IconButton>
                                <IconButton size={"small"} onClick={() => props.deleteSaving(saving)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </React.Fragment>
                        )
                    }

                    return null
                }
            }
        },
        {
            name: "Name",
            options: {
                filter: false
            }
        },
        {
            name: "Amount",
            options: {
                filter: false,
                customBodyRender: (value: any) => <Amount value={value} />
            }
        },
        {
            name: "Date",
            options: {
                sortDirection: "desc",
                filter: false,
                customBodyRender: value => <Moment format="DD MMM yyyy">{value}</Moment>
            }
        },
        {
            name: "Category",
            options: {
                filterList: filterList[5].length ? filterList[5] : undefined
            }
        },
        {
            name: "Account",
            options: {
                filterList: filterList[6].length ? filterList[6] : undefined
            }
        }
    ]

    const tableOptions: MUIDataTableOptions = {
        selectableRows: "none",
        elevation: 4,
        print: false,
        searchText: searchText,
        responsive: "scrollFullHeight",
        downloadOptions: {
            filename: 'savings.csv'
        },
        customToolbar: () => {
            return (
                <Tooltip title={"Add Saving"}>
                    <IconButton onClick={props.addSaving}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            data.forEach((row: any) => {
                row.data[4] = moment(row.data[4]).format("DD MMM YYYY")
            })
            return `${buildHead(columns)}${buildBody(data)}`.trim();
        },
        onFilterChange: (columnChanged: string, filterList: any) => {
            setFilterList(filterList)
        },
        onSearchChange: (text: string) => {
            setSearchText(text)
        },
        onTableChange: (_action: string, tableState: MUIDataTableState) => {
            let total = 0;
            tableState.displayData.forEach((row: any) => {
                const idx = row.dataIndex;
                total += tableState.data[idx].data[3]
            })
            setTotalAmount(total);
        }
    }

    const prepareData = (savings: Saving[], savingsCategories: Category[], accounts: Account[]) => {
        const tableData: TableData[] = [];

        savings.forEach(saving => {
            const category = _.find(savingsCategories, (e) => e.id === saving.savingsCategoryId);
            const account = _.find(accounts, (e) => e.id === saving.accountId)
            tableData.push({
                "Id": saving.id,
                "Name": saving.name,
                "Amount": saving.amount,
                "Date": saving.date,
                "Category": category ? category.name : "NA",
                "Account": account ? account.name : "NA"
            })
        })

        return tableData;
    }

    useEffect(() => {
        setTotalAmount(_.sumBy(props.savings, (e: Saving) => e.amount))
    }, [props.savings])

    return (
        <MUIDataTable
            title={<h3>Savings: <Amount value={totalAmount} /></h3>}
            data={prepareData(props.savings, props.savingsCategories, props.accounts)}
            columns={columnConfig}
            options={tableOptions}
        />
    )
}

export default SavingsTable