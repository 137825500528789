import React, { useState, useEffect } from "react";
import { last3MonthsStartDate, currentMonthEndDate } from "../../Duration/DurationConfig";
import { Grid } from "@material-ui/core";
import DurationPricker from "../../Duration/DurationPicker";
import SimpleBarChart from "../../Charts/SimpleBarChart";
import { getMonthlyOverview } from "../../../services/analyticsService";
import { convertDateToApiParamString } from "../../../utils/dateUtils";
import { MonthlyOverview } from "../Analytics";
import ChartHeader from "../../Charts/ChartHeader";
import moment from "moment";


const fetchMonthlyOverview = async (startDate: Date, endDate: Date) => {
    const response = await getMonthlyOverview({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}

const ComparisonByCategory = () => {
    const barChartBarConfig = [
        { key: 'Income', fillColor: 'green' },
        { key: 'Savings', fillColor: '#dd4477' },
        { key: 'Deductions', fillColor: 'red' },
        { key: 'Expenses', fillColor: '#3366cc' }
    ]
    const [dateRange, setDateRange] = useState({
        startDate: last3MonthsStartDate,
        endDate: currentMonthEndDate
    })
    const [barChartData, setBarChartData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const onDurationSelect = (range: Date[]) => {
        setDateRange({
            startDate: range[0],
            endDate: range[1]
        })
        setLoaded(false)
    }

    useEffect(() => {
        fetchMonthlyOverview(dateRange.startDate, dateRange.endDate)
            .then(data => {
                setBarChartData(
                    data.map((e: MonthlyOverview) => (
                        {
                            Date: moment(`${e.month}-${e.year}`, "MM-YYYY").format("MMM yyyy"),
                            Income: e.income,
                            Savings: e.savings,
                            Deductions: e.deductions,
                            Expenses: e.expenditure
                        }
                    ))
                )
                setLoaded(true)
            })
            .catch(e => setLoaded(true))
    }, [dateRange.startDate, dateRange.endDate])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DurationPricker
                    onSelect={onDurationSelect}
                    fromDate={dateRange.startDate}
                    toDate={dateRange.endDate}
                />
            </Grid>
            <Grid item xs={12}>
                <ChartHeader
                    title='Comparison by Category'
                    csvData={barChartData}
                />
                <SimpleBarChart
                    loaded={loaded}
                    data={barChartData}
                    xAxisDataKey="Date"
                    showLegend
                    barConfig={barChartBarConfig}
                />
            </Grid>
        </Grid>
    )
}

export default ComparisonByCategory