import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Moment from "react-moment";
import { Expense } from "../Expense";
import { Grid, Button } from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PaymentIcon from '@material-ui/icons/Payment';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

interface RecentExpensesListItemDetailsProps {
    expense: Expense,
    editExpense: (expense: Expense) => void
    deleteExpense: (expense: Expense) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(1),
        verticalAlign: "middle"
    },
    content: {

    }
}))

const RecentExpensesListItemDetails = (props: RecentExpensesListItemDetailsProps) => {
    const classes = useStyles();

    const editExpense = () => props.editExpense(props.expense)
    const deleteExpense = () => props.deleteExpense(props.expense)

    return (
        <div>
            <Grid container spacing={2} className={classes.root}>
                <Grid item>
                    <span className={classes.icon}>
                        <CategoryIcon fontSize="small" />
                    </span>
                    <span className={classes.content}>
                        {props.expense.categoryName}
                    </span>
                </Grid>
                <Grid item>
                    <span className={classes.icon}>
                        <ClassIcon fontSize="small" />
                    </span>
                    <span className={classes.content}>
                        {props.expense.subCategoryName ? props.expense.subCategoryName : "NA"}
                    </span>
                </Grid>
                <Grid item>
                    <span className={classes.icon}>
                        <PaymentIcon fontSize="small" />
                    </span>
                    <span className={classes.content}>
                        {props.expense.paymentType}
                    </span>
                </Grid>
                <Grid item>
                    <span className={classes.icon}>
                        <AccountBalanceIcon fontSize="small" />
                    </span>
                    <span className={classes.content}>
                        {props.expense.accountName}
                    </span>
                </Grid>
            </Grid>
            <div className="Form-actions">
                <Button onClick={editExpense} className="Form-action-btn" size="small" variant="contained" color="primary">
                    Edit
                </Button>
                <Button onClick={deleteExpense} className="Form-action-btn" size="small" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </div>
    )
}

export default RecentExpensesListItemDetails