import React from 'react';
import { Expense } from './Expense';
import { Button } from '@material-ui/core';

type DeleteExpenseFormProps = {
    expense: Expense
    submitForm: (formValues: Expense) => void
    closeForm: () => void
}

const DeleteExpenseForm = (props: DeleteExpenseFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.expense)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.expense)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteExpenseForm;