import React from 'react';
import { Category } from './Category';
import { Button } from '@material-ui/core';

type DeleteCategoryFormProps = {
    category: Category
    submitForm: (category: Category) => void
    closeForm: () => void
}

const DeleteCategoryForm = (props: DeleteCategoryFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.category)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.category)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteCategoryForm;