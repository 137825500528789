import React from 'react';
import moment from "moment";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowCharIcon from '@material-ui/icons/ShowChart';
import TabPanel from '../../Tabs/TabPanel';
import { DailyExpenditure, MonthlyOverview } from '../Analytics';
import SimpleBarChart from '../../Charts/SimpleBarChart';
import SimpleLineChart from '../../Charts/SimpleLineChart';
import { getDailyExpenditure, getMonthlyExpenseTrend } from '../../../services/analyticsService';
import { convertDateToApiParamString } from '../../../utils/dateUtils';
import ChartHeader from '../../Charts/ChartHeader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface ExpensesTrendTabProps {
    dailyExpenseTrendChartTitle: string
    monthlyExpenseTrendChartTitle: string
    dailyExpenditureStartDate: Date
    dailyExpenditureEndDate: Date
    monthlyExpenditureStartDate: Date
    monthlyExpenditureEndDate: Date
}

const fetchDailyExpenditure = async (startDate: Date, endDate: Date) => {
    const response = await getDailyExpenditure({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}

const fetchMonthlyExpenditure = async (startDate: Date, endDate: Date) => {
    const response = await getMonthlyExpenseTrend({
        startDate: convertDateToApiParamString(startDate),
        endDate: convertDateToApiParamString(endDate)
    })

    return response.data.data
}
const ExpensesTrendTab = (props: ExpensesTrendTabProps) => {
    const classes = useStyles();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const barChartBarConfig = [{
        key: 'Amount',
        fillColor: '#007bff'
    }]
    const lineChartLineConfig = [{
        key: 'Amount',
        fillColor: '#007bff'
    }]
    const [barChartData, setBarChartData] = React.useState([]);
    const [lineChartData, setLineChartData] = React.useState([]);
    const [barChartDataLoaded, setBarChartDataLoaded] = React.useState(false);
    const [lineChartDataLoaded, setLineChartDataLoaded] = React.useState(false);

    React.useEffect(() => {
        setBarChartDataLoaded(false)
        setLineChartDataLoaded(false)
        
        fetchDailyExpenditure(props.dailyExpenditureStartDate, props.dailyExpenditureEndDate)
            .then(data => {
                setBarChartData(
                    data.map((e: DailyExpenditure) => (
                        {
                            Date:  moment(`${e.date}-${e.month}-${e.year}`, "DD-MM-YYYY").format("DD MMM yyyy"),
                            Amount: e.total
                        }
                    ))
                )
                setBarChartDataLoaded(true)
            })
            .catch(e => setBarChartDataLoaded(true))

        fetchMonthlyExpenditure(props.monthlyExpenditureStartDate, props.monthlyExpenditureEndDate)
            .then(data => {
                setLineChartData(
                    data.map((e: MonthlyOverview) => (
                        {
                            Date: moment(`${e.month}-${e.year}`, "MM-YYYY").format("MMM yyyy"),
                            Amount: e.expenditure
                        }
                    ))
                )
                setLineChartDataLoaded(true)
            })
            .catch(e => setLineChartDataLoaded(true))
    }, [
        props.dailyExpenditureStartDate, props.dailyExpenditureEndDate,
        props.monthlyExpenditureStartDate, props.monthlyExpenditureEndDate
    ])

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="expenses-trend-tabs"
                >
                    <Tab label="Daily Expenses" icon={<BarChartIcon />} />
                    <Tab label="Monthly Expenses Trend" icon={<ShowCharIcon />} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTabIndex} index={0} id={'daily-expenses'} ariaLabel={'daily-expenses'}>
                <ChartHeader
                    title={props.dailyExpenseTrendChartTitle}
                    csvData={barChartData}
                />
                <SimpleBarChart
                    loaded={barChartDataLoaded}
                    data={barChartData}
                    xAxisDataKey="Date"
                    showLegend
                    barConfig={barChartBarConfig}
                />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} id={'monthly-expenses'} ariaLabel={'monthly-expenses'}>
                <ChartHeader
                    title={props.monthlyExpenseTrendChartTitle}
                    csvData={lineChartData}
                />
                <SimpleLineChart
                    loaded={lineChartDataLoaded}
                    data={lineChartData}
                    xAxisDataKey="Date"
                    showLegend
                    lineConfig={lineChartLineConfig}
                />
            </TabPanel>
        </div>
    );
}

ExpensesTrendTab.defaultProps = {
    dailyExpenseTrendChartTitle: 'Daily Expenditure',
    monthlyExpenseTrendChartTitle: 'Monthly Expenses Trend'
}

export default ExpensesTrendTab
