import React from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../../Login";
import Dashboard from "../../Dashboard";
import Profile from "../../Profile";
import Incomes from '../../Incomes';
import Savings from '../../Savings';
import Deductions from '../../Deductions';
import Expenses from '../../Expenses';
import ExpensesByCategory from '../../Analytics/ExpensesByCategory/index';
import ExpensesTrend from '../../Analytics/ExpensesTrend';
import ComparisonByCategory from '../../Analytics/ComparisonByCategory';
import AllTimeStats from '../../Analytics/AllTimeStats';
import IncomesByCategory from '../../Analytics/IncomesByCategory';
import SavingsByCategory from '../../Analytics/SavingsByCategory';
import DeductionsByCategory from '../../Analytics/DeductionsByCategory';
import PaymentMethods from '../../Analytics/PaymentMethods';
import Settings from '../../Settings';
import Accounts from '../../Accounts';

const Routes = () => {
    return(
        <Switch>
            <PublicRoute restrictedForSignedInUser exact path={'/'} component={Login}/>
            <PrivateRoute exact path={'/dashboard'} component={Dashboard}/>
            <PrivateRoute exact path={'/expenses'} component={Expenses}/>
            <PrivateRoute exact path={'/analytics/expenses-by-category'} component={ExpensesByCategory}/>
            <PrivateRoute exact path={'/analytics/expenses-trend'} component={ExpensesTrend}/>
            <PrivateRoute exact path={'/analytics/comparison-by-category'} component={ComparisonByCategory}/>
            <PrivateRoute exact path={'/analytics/all-time-stats'} component={AllTimeStats}/>
            <PrivateRoute exact path={'/analytics/incomes-by-category'} component={IncomesByCategory}/>
            <PrivateRoute exact path={'/analytics/savings-by-category'} component={SavingsByCategory}/>
            <PrivateRoute exact path={'/analytics/deductions-by-category'} component={DeductionsByCategory}/>
            <PrivateRoute exact path={'/analytics/payment-methods'} component={PaymentMethods}/>
            <PrivateRoute exact path={'/incomes'} component={Incomes}/>
            <PrivateRoute exact path={'/savings'} component={Savings}/>
            <PrivateRoute exact path={'/deductions'} component={Deductions}/>
            <PrivateRoute exact path={'/accounts'} component={Accounts}/>
            <PrivateRoute exact path={'/settings'} component={Settings}/>
            <PrivateRoute exact path={'/profile'} component={Profile}/>
        </Switch>
    )
}

export default Routes