import React from 'react';
import { Deduction } from './Deduction';
import { Button } from '@material-ui/core';

type DeleteDeductionFormProps = {
    deduction: Deduction
    submitForm: (formValues: Deduction) => void
    closeForm: () => void
}

const DeleteDeductionForm = (props: DeleteDeductionFormProps) => {
    return (
        <form onSubmit={() => props.submitForm(props.deduction)}>
            <div className="Form-input-container">
                <h3>Are you sure? This can't be undone.</h3>
            </div>
            <div className="Form-actions">
                <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="default">
                    Cancel
                </Button>
                <Button type={'submit'} onClick={() => props.submitForm(props.deduction)} className="Form-action-btn" variant="contained" color="secondary">
                    Delete
                </Button>
            </div>
        </form>
    )
}

export default DeleteDeductionForm;