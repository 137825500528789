import React, { Component } from 'react'
import _ from "lodash";
import { RootState } from "../../store";
import {
    fetchAllSavings,
    addSavings,
    editSavings,
    removeSavings
} from "../../store/savings/actions";
import { fetchAllAccounts } from "../../store/accounts/actions";
import { fetchAllSavingsCategories, addSavingsCategory, editSavingsCategory, removeSavingsCategory } from "../../store/categories/actions";
import { connect, ConnectedProps } from "react-redux";
import { Saving, AddOrEditSavingsRequestBody } from './Saving';
import FormDialog from '../Dialog/FormDialog';
import AddOrEditSavingsForm from './AddOrEditSavingsForm';
import DeleteSavingsForm from './DeleteSavingsForm';
import SavingsTable from './SavingsTable';
import { Grid } from '@material-ui/core';
import { Category } from '../Categories/Category';
import { AddOrEditCategoryRequestBody } from '../../store/categories/types';
import AddOrEditCategoryForm from '../Categories/AddOrEditCategoryForm';
import DeleteCategoryForm from '../Categories/DeleteCategoryForm';
import CategoryList from '../Categories/CategoryList';
import DurationPicker from '../Duration/DurationPicker';
import moment from "moment";
import { currentMonthStartDate, currentMonthEndDate } from '../Duration/DurationConfig';
import { convertDateToApiParamString, isWithInRange } from '../../utils/dateUtils';

const mapStateToProps = (state: RootState) => ({
    savings: state.savings,
    accounts: state.accounts,
    savingsCategories: state.categories.savings,
    defaultSavingsCategory: state.settings.preferences.defaultSavingsCategory,
    defaultAccountId: state.settings.preferences.defaultSavingsAccount
})
const mapDispatchToProps = {
    fetchAllSavings, addSavings,
    editSavings, removeSavings,
    fetchAllSavingsCategories, addSavingsCategory,
    editSavingsCategory, removeSavingsCategory,
    fetchAllAccounts
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type SavingsProps = ConnectedProps<typeof connector>
type SavingsFormType = 'add_saving' | 'edit_saving' | 'delete_saving'
type SavingsCategoryFormType = 'add_savings_category' | 'edit_savings_category' | 'delete_savings_category'
type FormType = SavingsFormType | SavingsCategoryFormType
type SelectedSaving = Saving | null
type SelectedSavingCategory = Category | null
type FormStatus = {
    formTitle: string
    formType: FormType | null
    selectedSaving: SelectedSaving
    selectedSavingCategory: SelectedSavingCategory
}
type SavingsState = {
    formStatus: FormStatus
}

class Savings extends Component<SavingsProps, SavingsState> {
    startDate: Date = currentMonthStartDate;
    endDate: Date = currentMonthEndDate;

    constructor(props: SavingsProps) {
        super(props)
        this.state = {
            formStatus: {
                formTitle: "",
                formType: null,
                selectedSaving: null,
                selectedSavingCategory: null
            }
        }
    }

    componentDidMount(): void {
        this.props.fetchAllSavings({
            startDate: convertDateToApiParamString(currentMonthStartDate),
            endDate: convertDateToApiParamString(currentMonthEndDate)
        })
        this.props.fetchAllSavingsCategories()
        this.props.fetchAllAccounts()
    }

    getDefaultSavings = (): Saving => {
        return {
            id: '', name: '', date: new Date().toISOString(),
            amount: 0, savingsCategoryId: this.props.defaultSavingsCategory,
            accountId: this.props.defaultAccountId
        }
    }

    setSavingsFormStatus = (formTitle: string, formType: SavingsFormType | null, selectedSaving: SelectedSaving = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedSaving, selectedSavingCategory: null
            }
        })
    }

    setSavingsCategoryFormStatus = (formTitle: string, formType: SavingsCategoryFormType | null, selectedSavingCategory: SelectedSavingCategory = null) => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle, formType, selectedSavingCategory, selectedSaving: null
            }
        })
    }

    closeForm = () => {
        this.setState({
            ...this.state,
            formStatus: {
                formTitle: "",
                formType: null,
                selectedSaving: null,
                selectedSavingCategory: null
            }
        })
    }

    renderFormDialog = () => {
        if (this.state.formStatus.formType) {
            let formComponent = null

            switch (this.state.formStatus.formType) {
                case 'add_saving': {
                    const submitForm = (formValues: AddOrEditSavingsRequestBody) => {
                        this.closeForm()
                        this.props.addSavings(formValues)
                    }
                    formComponent = <AddOrEditSavingsForm
                        accounts={this.props.accounts}
                        saving={this.getDefaultSavings()}
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                        savingsCategories={this.props.savingsCategories}
                    />
                    break
                }
                case 'edit_saving': {
                    if (this.state.formStatus.selectedSaving) {
                        const id = this.state.formStatus.selectedSaving.id
                        const submitForm = (formValues: AddOrEditSavingsRequestBody) => {
                            this.closeForm()
                            this.props.editSavings(id, formValues)
                        }
                        formComponent = <AddOrEditSavingsForm
                            accounts={this.props.accounts}
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            savingsCategories={this.props.savingsCategories}
                            saving={this.state.formStatus.selectedSaving}
                        />
                    }
                    break
                }
                case 'delete_saving': {
                    if (this.state.formStatus.selectedSaving) {
                        const id = this.state.formStatus.selectedSaving.id
                        const submitForm = () => {
                            this.closeForm()
                            this.props.removeSavings(id)
                        }
                        formComponent = <DeleteSavingsForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            saving={this.state.formStatus.selectedSaving}
                        />
                    }
                    break
                }
                case 'add_savings_category': {
                    const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                        this.closeForm()
                        this.props.addSavingsCategory(formValues)
                    }
                    formComponent = <AddOrEditCategoryForm
                        submitForm={submitForm}
                        closeForm={this.closeForm}
                    />
                    break
                }
                case 'edit_savings_category': {
                    if (this.state.formStatus.selectedSavingCategory) {
                        const id = this.state.formStatus.selectedSavingCategory.id
                        const submitForm = (formValues: AddOrEditCategoryRequestBody) => {
                            this.closeForm()
                            this.props.editSavingsCategory(id, formValues)
                        }
                        formComponent = <AddOrEditCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedSavingCategory}
                        />
                    }
                    break
                }
                case 'delete_savings_category': {
                    if (this.state.formStatus.selectedSavingCategory) {
                        const id = this.state.formStatus.selectedSavingCategory.id
                        const submitForm = () => {
                            this.closeForm()
                            this.props.removeSavingsCategory(id)
                        }
                        formComponent = <DeleteCategoryForm
                            submitForm={submitForm}
                            closeForm={this.closeForm}
                            category={this.state.formStatus.selectedSavingCategory}
                        />
                    }
                    break
                }
            }

            return (
                <FormDialog
                    title={this.state.formStatus.formTitle}
                    closeDialog={this.closeForm}
                    open
                >
                    {formComponent}
                </FormDialog>
            )
        }

        return null
    }

    onDurationSelect = (range: Date[]) => {
        this.startDate = range[0];
        this.endDate = range[1];

        this.props.fetchAllSavings({
            startDate: convertDateToApiParamString(range[0]),
            endDate: convertDateToApiParamString(range[1])
        })
    }

    render() {
        const savings = _.filter(this.props.savings, e => isWithInRange(new Date(e.date), this.startDate, this.endDate))

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DurationPicker onSelect={this.onDurationSelect} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <SavingsTable
                            accounts={this.props.accounts}
                            savings={savings}
                            savingsCategories={this.props.savingsCategories}
                            addSaving={() => this.setSavingsFormStatus("Add Saving", "add_saving")}
                            editSaving={(saving: Saving) => this.setSavingsFormStatus("Edit Saving", "edit_saving", saving)}
                            deleteSaving={(saving: Saving) => this.setSavingsFormStatus("Delete Saving", "delete_saving", saving)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CategoryList
                            title={'Saving Categories'}
                            categories={this.props.savingsCategories}
                            addCategory={() => this.setSavingsCategoryFormStatus('Add Saving Category', 'add_savings_category')}
                            editCategory={(c: Category) => this.setSavingsCategoryFormStatus('Edit Saving Category', 'edit_savings_category', c)}
                            deleteCategory={(c: Category) => this.setSavingsCategoryFormStatus('Delete Saving Category', 'delete_savings_category', c)}
                        />
                    </Grid>
                </Grid>
                {this.renderFormDialog()}
            </React.Fragment>
        )
    }
}

export default connector(Savings)