import React from 'react';
import { Formik, Form, Field, FormikErrors, FormikValues } from 'formik';
import { AddOrEditCategoryRequestBody } from '../../store/categories/types';
import { Category } from './Category';
import { TextField, Select } from 'formik-material-ui';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';

interface AddOrEditCategoryFormValues {
    name: string
    parentId: string | null
}

type AddOrEditCategoryFormProps = {
    category?: Category | null
    submitForm: (formValues: AddOrEditCategoryRequestBody) => void
    parentCategories?: Category[]
    closeForm: () => void
}

const AddOrEditCategoryForm = (props: AddOrEditCategoryFormProps) => {
    const initialValues: AddOrEditCategoryFormValues = {
        name: props.category ? props.category.name : '',
        parentId: props.category ? (props.category.parentId ? props.category.parentId : '-1') : '-1'
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={values => {
                const errors: FormikErrors<FormikValues> = {}
                if (!values.name || (!values.name.trim())) {
                    errors.name = "Name is required"
                }

                return errors
            }}
            onSubmit={(values) => {
                if (values.parentId === '-1') {
                    values.parentId = null
                }
                props.submitForm(values)
            }}
        >
            {({ isValid, dirty, submitForm }) => (
                <Form>
                    {props.parentCategories && props.parentCategories.length
                        ?
                        <FormControl fullWidth>
                            <InputLabel htmlFor="parent-id">Parent Category</InputLabel>
                            <Field
                                className={'Form-input'}
                                component={Select}
                                name="parentId"
                                inputProps={{
                                    id: 'parent-id',
                                    autocomplete: "off"
                                }}
                            >
                                <MenuItem value={'-1'}>None</MenuItem>
                                {
                                    props.parentCategories.map((c: Category) => (
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                    ))
                                }
                            </Field>
                        </FormControl>
                        : null
                    }
                    <FormControl fullWidth>
                        <Field
                            autoFocus
                            className={'Form-input'}
                            component={TextField}
                            name="name"
                            label="Name"
                            required
                            inputProps={{
                                autocomplete: "off"
                            }}
                        />
                    </FormControl>
                    <div className="Form-actions">
                        <Button onClick={props.closeForm} className="Form-action-btn" variant="contained" color="secondary">
                            Cancel
                        </Button>
                        <Button disabled={!dirty || !isValid} type="submit" onClick={submitForm} className="Form-action-btn" variant="contained" color="primary">
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default AddOrEditCategoryForm;