import React from "react";
import { Box } from "@material-ui/core";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    id: any
    ariaLabel: any
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, id, ariaLabel, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-${index}`}
            aria-labelledby={`${ariaLabel}-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel