import React from "react";
import { Grid } from "@material-ui/core";
import Preferences from "./Preferences";

const Settings = () => {
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Preferences/>
            </Grid>
        </Grid>
    )
}

export default Settings