import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import _ from "lodash";
import { Deduction } from "./Deduction";
import { Category } from "../Categories/Category";
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Amount from "../App/Currency/Amount";
import moment from "moment";
import { Account } from "../Accounts/Account";

type DeductionsTableProps = {
    deductions: Deduction[]
    accounts: Account[]
    deductionsCategories: Category[]
    addDeduction: () => void
    editDeduction: (deduction: Deduction) => void
    deleteDeduction: (deduction: Deduction) => void
}

type TableData = {
    Id: string
    Name: string
    Amount: number
    Date: string
    Category: string,
    Account: string
}

const DeductionsTable = (props: DeductionsTableProps) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [filterList, setFilterList] = useState([
        [], [], [], [], [], [], []
    ])
    const [searchText, setSearchText] = useState("")
    const columnConfig: MUIDataTableColumnDef[] = [
        {
            name: "Id",
            options: {
                display: 'excluded',
                download: false,
                filter: false,
                sort: false,
                searchable: false
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id: string = tableMeta.rowData[0]
                    const deduction: Deduction | undefined = _.find(props.deductions, { id })
                    if (deduction) {
                        return (
                            <React.Fragment>
                                <IconButton size={"small"} onClick={() => props.editDeduction(deduction)}>
                                    <EditIcon fontSize={'small'} />
                                </IconButton>
                                <IconButton size={"small"} onClick={() => props.deleteDeduction(deduction)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </React.Fragment>
                        )
                    }

                    return null
                }
            }
        },
        {
            name: "Name",
            options: {
                filter: false
            }
        },
        {
            name: "Amount",
            options: {
                filter: false,
                customBodyRender: (value: any) => <Amount value={value} />
            }
        },
        {
            name: "Date",
            options: {
                sortDirection: "desc",
                filter: false,
                customBodyRender: value => <Moment format="DD MMM yyyy">{value}</Moment>
            }
        },
        {
            name: "Category",
            options: {
                filterList: filterList[5].length ? filterList[5] : undefined
            }
        },
        {
            name: "Account",
            options: {
                filterList: filterList[6].length ? filterList[6] : undefined
            }
        }
    ]

    const tableOptions: MUIDataTableOptions = {
        selectableRows: "none",
        elevation: 4,
        print: false,
        searchText: searchText,
        responsive: "scrollFullHeight",
        downloadOptions: {
            filename: 'deductions.csv'
        },
        customToolbar: () => {
            return (
                <Tooltip title={"Add Deduction"}>
                    <IconButton onClick={props.addDeduction}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            data.forEach((row: any) => {
                row.data[4] = moment(row.data[4]).format("DD MMM YYYY")
            })
            return `${buildHead(columns)}${buildBody(data)}`.trim();
        },
        onFilterChange: (columnChanged: string, filterList: any) => {
            setFilterList(filterList)
        },
        onSearchChange: (text: string) => {
            setSearchText(text)
        },
        onTableChange: (_action: string, tableState: MUIDataTableState) => {
            let total = 0;
            tableState.displayData.forEach((row: any) => {
                const idx = row.dataIndex;
                total += tableState.data[idx].data[3]
            })
            setTotalAmount(total);
        }
    }

    const prepareData = (deductions: Deduction[], deductionsCategories: Category[], accounts: Account[]) => {
        const tableData: TableData[] = [];

        deductions.forEach(deduction => {
            const category = _.find(deductionsCategories, (e) => e.id === deduction.deductionCategoryId);
            const account = _.find(accounts, (e) => e.id === deduction.accountId)
            tableData.push({
                "Id": deduction.id,
                "Name": deduction.name,
                "Amount": deduction.amount,
                "Date": deduction.date,
                "Category": category ? category.name : "NA",
                "Account": account ? account.name : "NA"
            })
        })

        return tableData;
    }

    useEffect(() => {
        setTotalAmount(_.sumBy(props.deductions, (e: Deduction) => e.amount))
    }, [props.deductions])

    return (
        <MUIDataTable
            title={<h3>Deductions: <Amount value={totalAmount} /></h3>}
            data={prepareData(props.deductions, props.deductionsCategories, props.accounts)}
            columns={columnConfig}
            options={tableOptions}
        />
    )
}

export default DeductionsTable