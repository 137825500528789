import apiClient from './apiClient'
import { AddOrEditExpenseRequestBody } from '../components/Expenses/Expense'

export const getAllExpenses = (params?: any) => apiClient.get("/expense", {params})
export const getRecentExpenses = (params?: any) => apiClient.get("/expense/recent", {params})
export const createExpense = (requestBody: AddOrEditExpenseRequestBody) => (
    apiClient.post("/expense", requestBody)
)
export const updateExpense = (id: string, requestBody: AddOrEditExpenseRequestBody) => (
    apiClient.put(`/expense/${id}`, requestBody)
)
export const deleteExpense = (id: string) => apiClient.delete(`/expense/${id}`)