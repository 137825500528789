import { batch } from "react-redux";
import { AppThunk } from "..";
import { hidePageLoader, showNotification, showPageLoader, showApiError } from "../app/actions";
import { FETCH_ALL_DEDUCTIONS, REMOVE_DEDUCTION, ADD_DEDUCTION, EDIT_DEDUCTION } from "./types";
import { AddOrEditDeductionRequestBody } from "../../components/Deductions/Deduction";
import { createDeduction, updateDeduction, deleteDeduction, getAllDeductions } from "../../services/deductionsService";

export const fetchAllDeductions = (params?: any): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await getAllDeductions(params)

        batch(() => {
            dispatch({
                type: FETCH_ALL_DEDUCTIONS,
                payload: response.data.data
            })
    
            dispatch(hidePageLoader())
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const addDeduction = (requestBody: AddOrEditDeductionRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await createDeduction(requestBody)

        batch(() => {
            dispatch({
                type: ADD_DEDUCTION,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Deduction added successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const editDeduction = (id: string, requestBody: AddOrEditDeductionRequestBody): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await updateDeduction(id, requestBody)

        batch(() => {
            dispatch({
                type: EDIT_DEDUCTION,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            dispatch(showNotification("Deduction updated successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}

export const removeDeduction = (id: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await deleteDeduction(id)

        batch(() => {
            dispatch({
                type: REMOVE_DEDUCTION,
                payload: {id}
            })
            
            dispatch(hidePageLoader())
            dispatch(showNotification("Deduction removed successfully"))
        })
    } catch(e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showApiError(e))
        })
    }
}