import apiClient from './apiClient'
import { AddOrEditIncomeRequestBody } from '../components/Incomes/Income'

export const getAllIncomes = (params?: any) => apiClient.get("/income",{params})
export const createIncome = (requestBody: AddOrEditIncomeRequestBody) => (
    apiClient.post("/income", requestBody)
)
export const updateIncome = (id: string, requestBody: AddOrEditIncomeRequestBody) => (
    apiClient.put(`/income/${id}`, requestBody)
)
export const deleteIncome = (id: string) => apiClient.delete(`/income/${id}`)